import { object, string, date, boolean } from 'yup';
import { applySpec, propOr, pathOr, pipe, when, is, isEmpty, pick, keys, path } from 'ramda';

import { WARD, DIABETES, REPRESENTATIVE, SUB_EYE_DOCTOR, SUB_GP, PATIENT, SUB_ORGANIZATION } from 'store/planning';
import {
  BIRTH,
  DATE,
  FIRST_NAME,
  LAST_NAME,
  REPORTER,
  TITLE,
  INSTITUTION,
  GENDER,
  ADDRESS,
  ZIP_CODE,
  CANTON,
  COUNTRY,
  CITY,
  PHONE,
  EMAIL,
} from 'store/patients';
import { getHeadTitleOfPatient, parseUTCDate } from 'utils';

export const getPageTitle = ({ firstName, lastName, birth, publicId }) =>
  getHeadTitleOfPatient(firstName, lastName, birth, publicId);

export const getInitialValues = ({ firstName, lastName, birth, ward, date: d, diabetes, represent, eyeDoctor, gp }, shifts) => ({
  [FIRST_NAME]: firstName || '',
  [LAST_NAME]: lastName || '',
  [BIRTH]: parseUTCDate(birth) || null,
  [WARD]: ward || '',
  [DATE]: d || shifts[0][0],
  [DIABETES]: diabetes,
  [SUB_EYE_DOCTOR]: eyeDoctor || null,
  [SUB_GP]: gp || null,
  [REPRESENTATIVE]: applySpec({
    [REPORTER]: propOr(false, REPORTER),
    [TITLE]: propOr('', TITLE),
    [FIRST_NAME]: propOr('', FIRST_NAME),
    [LAST_NAME]: propOr('', LAST_NAME),
    [INSTITUTION]: propOr('', INSTITUTION),
    [GENDER]: propOr('', GENDER),
    [ADDRESS]: propOr('', ADDRESS),
    [ZIP_CODE]: propOr('', ZIP_CODE),
    [CANTON]: propOr('', CANTON),
    [COUNTRY]: propOr('', COUNTRY),
    [CITY]: propOr('', CITY),
    [PHONE]: propOr('', PHONE),
    [EMAIL]: propOr('', EMAIL),
  })(represent),
});

export const validationSchema = object({
  [FIRST_NAME]: string().trim().required('Vorname fehlt'),
  [LAST_NAME]: string().trim().required('Nachname fehlt'),
  [BIRTH]: date().max(new Date(), 'Geburtsdatum ungültig').typeError('Geburtsdatum fehlt'),
  [WARD]: string().notRequired(),
  [DATE]: date().required('Datum ist erforderlich'),
  [DIABETES]: boolean().nullable().notRequired(),
  [REPRESENTATIVE]: object({
    [REPORTER]: boolean(),
    [TITLE]: string().notRequired(),
    [FIRST_NAME]: string().notRequired(),
    [LAST_NAME]: string().notRequired(),
    [INSTITUTION]: string()
      .max(45, 'Der Name der Institution darf max. 45 Zeichen betragen. Der Wert wird nicht gespeichert.')
      .notRequired(),
    [GENDER]: string().notRequired(),
    [ADDRESS]: string().notRequired(),
    [ZIP_CODE]: string()
      .matches(/(^\d{4}$|^$)/, 'PLZ ungültig')
      .notRequired(),
    [CANTON]: string().notRequired(),
    [COUNTRY]: string().notRequired(),
    [CITY]: string().notRequired(),
    [PHONE]: string().notRequired(),
    [EMAIL]: string().trim().customEmail('Die E-Mail-Adresse ist ungültig und kann nicht gespeichert werden.').notRequired(),
  }),
});

export const excludeErrorFields = (values, errors) =>
  isEmpty(pick([FIRST_NAME, LAST_NAME, BIRTH], errors))
    ? keys(values).reduce((acc, key) => {
        if (errors[key]) return acc;
        if (key === REPRESENTATIVE) {
          acc[key] = keys(values[key]).reduce(
            (subAcc, subKey) => (errors[`${key}.${subKey}`] ? subAcc : { ...subAcc, [subKey]: path([key, subKey], values) }),
            {}
          );

          return acc;
        }

        acc[key] = values[key];

        return acc;
      }, {})
    : null;

export const DIABETES_RADIOS = [
  { label: 'Ja', value: true },
  { label: 'Nein', value: false },
];

export const extractFormDataFromMedia = (data) => ({
  [FIRST_NAME]: pathOr('', [PATIENT, FIRST_NAME], data),
  [LAST_NAME]: pathOr('', [PATIENT, LAST_NAME], data),
  [BIRTH]: pipe(pathOr(null, [PATIENT, BIRTH]), when(Boolean, parseUTCDate))(data),
  [SUB_EYE_DOCTOR]: pipe(
    propOr(null, SUB_EYE_DOCTOR),
    when(is(String), ($) => ({ [SUB_ORGANIZATION]: $ }))
  )(data),
  [SUB_GP]: pipe(
    propOr(null, SUB_GP),
    when(is(String), ($) => ({ [SUB_ORGANIZATION]: $ }))
  )(data),
  [REPRESENTATIVE]: applySpec({
    [FIRST_NAME]: propOr('', FIRST_NAME),
    [LAST_NAME]: propOr('', LAST_NAME),
    [ADDRESS]: propOr('', ADDRESS),
    [ZIP_CODE]: propOr('', ZIP_CODE),
    [COUNTRY]: propOr('', COUNTRY),
    [CANTON]: propOr('', CANTON),
    [CITY]: propOr('', CITY),
    [EMAIL]: propOr('', EMAIL),
  })(data[REPRESENTATIVE] || {}),
});
