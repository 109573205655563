import React, { useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { keys, isEmpty, inc, pipe, omit, length } from 'ramda';
import PropTypes from 'prop-types';

import { useScheduleUpdates, usePlanningActions, useAlerts, ID, FILE, REGISTRATIONS, SHIFTS, RESCHEDULE } from 'store/planning';
import Button from 'components/Button';
import Message from 'components/Message';

import UploadXLS from '../UploadXLS';
import Checkbox from './Checkbox';
import { BtnWrap } from './styles';

const Uploading = ({ saveSchedule, saveError, setUnsubscribeTableKey, disabled, readMode, isAllRegistrationBlocked }) => {
  const { id } = useParams();
  const updates = useScheduleUpdates(id);
  const hasChanges = useMemo(() => {
    if (!updates) return false;
    if (updates[SHIFTS]) return true;
    if (updates[RESCHEDULE]) return true;

    const amount = pipe(omit([SHIFTS, RESCHEDULE]), keys, length)(updates);

    if (amount === 0) return false;
    if (amount > 1) return true;
    if (!updates[REGISTRATIONS]) return true;

    return !isEmpty(updates[REGISTRATIONS]);
  }, [updates]);
  const { saveScheduleXML, resetUpdates } = usePlanningActions();
  const { action, loading, success, error, errors } = useAlerts(saveScheduleXML);
  const onChange = useCallback((file) => action({ [ID]: id, [FILE]: file }), [action, id]);
  const resetChanges = useCallback(() => resetUpdates(id), [id, resetUpdates]);
  const onSubmit = useCallback(() => saveSchedule(), [saveSchedule]);

  useEffect(() => {
    if (success) setUnsubscribeTableKey(inc);
  }, [success, setUnsubscribeTableKey]);

  return (
    <>
      <UploadXLS
        label="Terminplan aktualisieren"
        onChange={onChange}
        disabled={disabled || readMode}
        loading={loading}
        error={error}
        errors={errors}
      />
      <BtnWrap>
        <Checkbox disabled={disabled || loading} readMode={readMode} isAllRegistrationBlocked={isAllRegistrationBlocked} />
        <Button onClick={resetChanges} disabled={disabled || loading || !hasChanges || (readMode && isAllRegistrationBlocked)}>
          Änderungen zurücksetzen
        </Button>
        <Button
          onClick={onSubmit}
          color="success"
          disabled={disabled || loading || !hasChanges || (readMode && isAllRegistrationBlocked)}
        >
          Terminplan aktualisieren
        </Button>
      </BtnWrap>
      {saveError && (
        <Message type="error" small={1} form={1} textAlign="right">
          {saveError}
        </Message>
      )}
    </>
  );
};

Uploading.defaultProps = { saveError: null, disabled: false };
Uploading.propTypes = {
  saveSchedule: PropTypes.func.isRequired,
  saveError: PropTypes.string,
  setUnsubscribeTableKey: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readMode: PropTypes.bool.isRequired,
  isAllRegistrationBlocked: PropTypes.bool.isRequired,
};

export default Uploading;
