import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Msg from 'components/Message';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const HeaderText = styled.p`
  display: block;
  font-size: var(--font-size10);
  font-weight: 600;
  letter-spacing: 1.01px;
  color: ${({ $color }) => $color || 'var(--color-grey6A)'};
  text-transform: uppercase;
  white-space: nowrap;
`;

export const NavLink = styled(Link)`
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  font-size: var(--font-size13);
  font-weight: 500;
  letter-spacing: 0.92px;
  color: var(--color-grey6A);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 15px;
  margin: 5px 0 15px;
  @media (hover) {
    &:hover {
      background-color: var(--color-greyF8);
    }
  }
  &:active {
    background-color: #bdbcbc;
  }
`;

export const Message = styled(Msg).attrs(() => ({
  type: 'error',
  form: 1,
  small: 1,
}))`
  text-align: right;
`;

export const Title = styled.h3`
  border-top: 1px solid var(--color-grey97);
  font-size: var(--font-size14);
  font-weight: var(--font-weight500);
  color: var(--color-grey6A);
  padding-top: 12px;
  margin-top: 12px;
`;
