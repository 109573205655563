import styled from 'styled-components';
import { Link } from 'react-router-dom';

import PageHeader from 'components/PageHeader';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0 50px;
`;

export const Header = styled(PageHeader)`
  margin-bottom: 0;
`;

export const NavLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyF8);
  margin-bottom: 30px;
  padding: 0 10px;
  text-decoration: none;
  & > span {
    font-size: var(--font-size18);
    color: var(--color-grey6A);
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-greyEC);
    }
  }
`;
