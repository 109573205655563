import styled from 'styled-components';

import SelectDef from 'components/SelectNew';
import Msg from 'components/Message';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 375px;
  gap: 25px;
  width: 100%;
  padding-top: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 130px repeat(3, auto);
  justify-content: start;
  column-gap: 10px;
  width: 100%;
  position: relative;
`;

export const Label = styled.p`
  align-self: center;
  font-size: var(--font-size13);
  font-weight: var(--font-weight600);
  letter-spacing: 0.92px;
  color: var(--color-grey6A);
  text-transform: uppercase;
`;

export const Select = styled(SelectDef).attrs(() => ({ mb: 0, mini: true }))`
  align-self: flex-start;
  width: auto;
`;

export const SmallText = styled.p`
  align-self: flex-start;
  font-size: var(--font-size10);
  font-weight: var(--font-weight500);
  color: var(--color-grey6A);
  line-height: 26px;
`;

export const Text = styled.p`
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  color: var(--color-grey6A);
  text-transform: uppercase;
`;

export const Status = styled.p`
  align-self: flex-start;
  font-size: var(--font-size10);
  font-weight: var(--font-weight600);
  color: ${({ $color }) => $color || 'var(--color-grey6A)'};
  letter-spacing: 1.01px;
  line-height: 26px;
  text-transform: uppercase;
`;

export const Button = styled.button.attrs(() => ({ type: 'button' }))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${({ $error }) => ($error ? 'var(--color-orangeF5)' : '#21ea9f')};
  & g {
    stroke: ${({ $error }) => ($error ? 'var(--color-orangeF5)' : '#21ea9f')};
  }
`;

export const Message = styled(Msg).attrs(() => ({
  type: 'error',
  form: 1,
  small: 1,
}))`
  position: absolute;
  inset: 26px 0 auto 140px;
`;
