import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const SET_CURRENT = getName('SET_CURRENT');
export const RESET_CURRENT = getName('RESET_CURRENT');
export const SET_SCHEDULE_UPDATES = getName('SET_SCHEDULE_UPDATES');
export const SET_NEW_REGISTRATION = getName('SET_NEW_REGISTRATION');
export const RESET_UPDATES_PROP = getName('RESET_UPDATES_PROP');
export const RESET_UPDATES = getName('RESET_UPDATES');
export const UPDATE_STATUS = getName('UPDATE_STATUS');
export const UPDATE_SEARCH = getName('UPDATE_SEARCH');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const RESET_FILTERS = getName('RESET_FILTERS');

export const CREATE_SCHEDULE = getName('CREATE_SCHEDULE');
export const FETCH_SCHEDULES = getName('FETCH_SCHEDULES');
export const FETCH_SCHEDULE = getName('FETCH_SCHEDULE');
export const FETCH_SCHEDULE_UNSUBSCRIBERS = getName('FETCH_SCHEDULE_UNSUBSCRIBERS');
export const CREATE_SCHEDULE_XML = getName('CREATE_SCHEDULE_XML');
export const SAVE_SCHEDULE_XML = getName('SAVE_SCHEDULE_XML');
export const SAVE_SCHEDULE = getName('SAVE_SCHEDULE');
export const CREATE_REGISTRATION = getName('CREATE_REGISTRATION');
export const SAVE_REGISTRATION = getName('SAVE_REGISTRATION');
export const REMOVE_PATIENT_FROM_SCHEDULE = getName('REMOVE_PATIENT_FROM_SCHEDULE');
export const EXPORT_SCHEDULE = getName('EXPORT_SCHEDULE');
export const GET_SCHEDULE_LABELS = getName('GET_SCHEDULE_LABELS');
export const FETCH_SCHEDULE_FORMS = getName('FETCH_SCHEDULE_FORMS');
export const CREATE_SCHEDULE_FORMS = getName('CREATE_SCHEDULE_FORMS');
export const UPLOAD_SCHEDULE_FORM_MEDIA = getName('UPLOAD_SCHEDULE_FORM_MEDIA');
export const SAVE_SCHEDULE_FORM_MEDIAS = getName('SAVE_SCHEDULE_FORM_MEDIAS');
export const SAVE_SCHEDULE_FORM_MEDIA = getName('SAVE_SCHEDULE_FORM_MEDIA');
export const REMOVE_SCHEDULE_FORM_MEDIA = getName('REMOVE_SCHEDULE_FORM_MEDIA');
