import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ifElse, anyPass, not, pipe, takeLast, equals } from 'ramda';
import PropTypes from 'prop-types';
import { FIRST_NAME, LAST_NAME, usePatient, GENDER } from 'store/patients';
import { SALUTATION } from 'utils/constants';
import { Btn } from './styles';

const Button = ({ title, text, value, setValue, width }) => {
  const { id } = useParams();
  const [firstName, lastName, gender] = usePatient(id, [FIRST_NAME, LAST_NAME, GENDER]);

  const patientName = useMemo(() => {
    if (!firstName || !lastName) return 'die Patientin / den Patienten';
    return `${SALUTATION[gender] ? `${SALUTATION[gender]} ` : ''}${firstName} ${lastName}`;
  }, [firstName, lastName, gender]);

  const handleClick = useCallback(() => {
    ifElse(
      anyPass([not, pipe(takeLast(1), equals(' '))]),
      ($) => setValue(`${$}${text.replace('%PATIENT%', patientName)}`),
      ($) => setValue(`${$} ${text.replace('%PATIENT%', patientName)}`)
    )(value);
  }, [setValue, text, value, patientName]);

  return (
    <Btn type="button" onClick={handleClick} width={width}>
      {title}
    </Btn>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
};

export default Button;
