import { useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { values } from 'ramda';

import { useAlerts } from 'store/alerts/hooks';
import { wrapActions, wrapSelector, wrapSelectorWithArg, wrapMultiSelector } from 'store/utils';
import { SUB_EXAMINATION } from 'api/consts';

import {
  getDiagnoses,
  getDiagnosisReports,
  getDiagnosisReport,
  getDiagnosisPreviewReport,
  getDiagnosisCodes,
  getCurrentDiagnosis,
  getCurrentDiagnosisProp,
} from './selectors';
import * as actions from './actions';

export const useDiagnoses = () => {
  const diagnoses = useSelector(getDiagnoses);

  return useMemo(() => values(diagnoses), [diagnoses]);
};
export const useDiagnosisReports = () => {
  const diagnosisReports = useSelector(getDiagnosisReports);

  return useMemo(() => values(diagnosisReports), [diagnosisReports]);
};

export const useDiagnosisReport = wrapSelectorWithArg(getDiagnosisReport);
export const useDiagnosisPreviewReport = wrapSelector(getDiagnosisPreviewReport);

export const useCurrentDiagnosis = wrapMultiSelector(getCurrentDiagnosisProp, getCurrentDiagnosis);

export const useDiagnosisCodes = () => {
  const codes = useSelector(getDiagnosisCodes);

  return useMemo(
    () =>
      codes.reduce(
        (acc, item) => {
          acc[item?.private ? 'internCodes' : 'externCodes'].push(item);
          return acc;
        },
        { internCodes: [], externCodes: [] }
      ),
    [codes]
  );
};

export const useDiagnosesActions = wrapActions({ ...actions });

export const useInitDiagnosesCodes = (hasAccess) => {
  const alerts = useAlerts();
  const { fetchDiagnosisCodes } = useDiagnosesActions();
  const { id, extractId } = alerts;

  useEffect(() => {
    if (hasAccess && !id) {
      extractId(fetchDiagnosisCodes());
    }
  }, [fetchDiagnosisCodes, hasAccess, id, extractId]);

  return hasAccess ? alerts : { success: true };
};

export const useInitDiagnoses = (hasAccess, examinationId) => {
  const alerts = useAlerts();
  const { id, extractId, resetAlerts, success, loading } = alerts;
  const diagnosisExamId = useCurrentDiagnosis(SUB_EXAMINATION);
  const { fetchDiagnoses, resetDiagnoses, resetDiagnosisReports } = useDiagnosesActions();

  const reset = useCallback(() => {
    resetDiagnoses();
    resetDiagnosisReports();
    resetAlerts();
  }, [resetAlerts, resetDiagnoses, resetDiagnosisReports]);

  useEffect(() => {
    if (hasAccess && examinationId && !id) {
      extractId(fetchDiagnoses(examinationId));
    }
  }, [examinationId, extractId, fetchDiagnoses, hasAccess, id]);

  useEffect(() => {
    if (success && (!hasAccess || examinationId !== diagnosisExamId)) {
      reset();
    }
  }, [diagnosisExamId, examinationId, hasAccess, reset, success]);

  return hasAccess && examinationId ? { ...alerts, loading: !id || loading } : { success: true };
};
