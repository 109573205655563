import { useEffect, useMemo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { values, props } from 'ramda';

import { wrapActions, wrapSelectorWithArg, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { BIRTH } from 'store/patients';
import { SORT_BY, SORT_DIR, CURSOR, LIMIT, SORT, PAGINATION, SEARCH, FIELDS } from 'api/consts';
import { handleStaticSort } from 'utils';
import { ORGANIZATION, usePermissions } from 'permissions';

import * as actions from './actions';
import {
  getOrganizations,
  getImports,
  getOrganizationsFilters,
  getCurrentOrganizationProp,
  getCurrentOrganization,
  getOrganization,
} from './selectors';
import { SUCCESS_IMPORTS, ERROR_IMPORTS, KIND, NAME, CITY, CONTACTS } from './consts';

export const useOrganizationActions = wrapActions({ ...actions });
export const useCollectOrganizationsAction = (kind, extraParams) => {
  const mounted = useRef();
  const { fetchInitCollectOrganizations, fetchCollectOrganizations } = useOrganizationActions();
  const collectAction = useCallback(
    (params) => {
      const action = mounted.current ? fetchCollectOrganizations : fetchInitCollectOrganizations;
      return action({ [KIND]: kind, ...extraParams, ...params });
    },
    [fetchInitCollectOrganizations, fetchCollectOrganizations, kind, extraParams]
  );

  useEffect(() => {
    mounted.current = true;
  }, []);

  return collectAction;
};

export const useInitOrganization = (hasAccess) => {
  const { id, extractId, resetAlerts, ...alerts } = useAlerts();
  const { fetchCurrentOrganization, updateCurrentOrganization } = useOrganizationActions();

  useEffect(() => {
    if (hasAccess && !id) extractId(fetchCurrentOrganization());
    if (!hasAccess && id) {
      updateCurrentOrganization();
      resetAlerts();
    }
  }, [extractId, fetchCurrentOrganization, hasAccess, id, resetAlerts, updateCurrentOrganization]);

  return hasAccess ? alerts : { success: true };
};
export const useCurrentOrganization = wrapMultiSelector(getCurrentOrganizationProp, getCurrentOrganization);

export const useOrganizationsFilters = wrapSelectorWithArg(getOrganizationsFilters);

export const useOrganizations = (kind) => {
  const can = usePermissions();
  const hasAccess = can.read(ORGANIZATION);
  const { id, extractId, loading } = useAlerts();

  const { fetchOrganizations } = useOrganizationActions();
  const filters = useOrganizationsFilters(kind);
  const [search, sort, pagination] = props([SEARCH, SORT, PAGINATION], filters);
  const [cursor, limit] = props([CURSOR, LIMIT], pagination);
  const organizations = useSelector(getOrganizations(kind));
  const list = useMemo(() => values(organizations), [organizations]);

  const requestData = useCallback(() => {
    if (!hasAccess) return;
    extractId(
      fetchOrganizations({
        [FIELDS]: [KIND, NAME, CITY, CONTACTS],
        [KIND]: kind,
        [SEARCH]: search,
        [CURSOR]: cursor,
        [LIMIT]: limit,
        ...sort,
      })
    );
  }, [cursor, extractId, fetchOrganizations, limit, search, sort, kind, hasAccess]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  return { list, loading: !id || loading, filters };
};

export const useOrganization = (orgId) => {
  const can = usePermissions();
  const hasAccess = can.read(ORGANIZATION);
  const { id, extractId, loading, success } = useAlerts();
  const kind = success && success[KIND];
  const { fetchOrganization } = useOrganizationActions();
  const organization = useSelector(getOrganization(kind, orgId)) || success;

  useEffect(() => {
    if (hasAccess && orgId) extractId(fetchOrganization(orgId));
  }, [extractId, fetchOrganization, hasAccess, orgId]);

  return { loading: hasAccess && orgId && (!id || loading), organization };
};

const importsMap = {
  [SUCCESS_IMPORTS]: 'success',
  [ERROR_IMPORTS]: 'error',
};

export const useImports = (type) => {
  const imports = useSelector(getImports(importsMap[type]));
  const { resetImports, resetFilters } = useOrganizationActions();
  const filters = useOrganizationsFilters(type);
  const sort = filters[SORT];
  const [sortBy, sortDir] = props([SORT_BY, SORT_DIR], sort);

  const isDateField = useMemo(() => [BIRTH, 'examinationDate'].includes(sortBy), [sortBy]);

  const list = useMemo(() => handleStaticSort(sortBy, sortDir, imports, isDateField), [sortBy, sortDir, imports, isDateField]);

  useEffect(
    () => () => {
      resetImports();
      resetFilters(SUCCESS_IMPORTS);
      resetFilters(ERROR_IMPORTS);
    },
    [resetImports, resetFilters]
  );

  return { list, sort };
};
