import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Item = styled.div`
  width: ${({ width }) => `calc(${width} - 10px)`};
  font-size: var(--font-size20);
  color: var(--color-grey6A);
  text-align: left;
`;
