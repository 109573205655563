import React, { useState, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import { useParams, Redirect } from 'react-router-dom';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';

import { usePlanningActions, useAlerts, SUB_ORGANIZATION, ID, DATE, DAYS, SHIFTS } from 'store/planning';
import planningTemplate from 'assets/planningTemplate.xlsx';
import Section from '../Section';
import XlsErrors from '../XlsErrors';

import { getInitialValues, validationSchema } from './utils';
import Form from './Form';
import { Container, Button, Link } from './styles';

const New = ({ data, onNext }) => {
  const { id } = useParams();
  const isNew = id === 'new';
  const [dateError, setDateError] = useState();
  const { createSchedule, saveSchedule } = usePlanningActions();
  const { action, loading, success, error, errors: xlsErrors } = useAlerts(isNew ? createSchedule : saveSchedule);
  const initialValues = useMemo(() => getInitialValues(data || {}), [data]);
  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: action,
  });

  useEffect(() => {
    if (!isNew && typeof onNext === 'function' && success) onNext();
  }, [isNew, onNext, success]);

  if (isNew && success) return <Redirect to={`/planning/${success}`} />;

  return (
    <Container onSubmit={handleSubmit}>
      <Section title="Terminplan erstellen" current={1} amount={2} withCounter />
      <Form values={values} setFieldValue={setFieldValue} errors={errors} setDateError={setDateError} disabled={loading} />
      <Button
        type="submit"
        color="normal"
        disabled={loading || Boolean(dateError) || !(values[SUB_ORGANIZATION] && isEmpty(errors))}
      >
        Weiter
      </Button>
      <Link href={planningTemplate} download="Template herunterladen" target="_blank" rel="noopener noreferrer">
        Template herunterladen
      </Link>
      {error && <XlsErrors error={error} errors={xlsErrors} />}
    </Container>
  );
};

New.defaultProps = {
  data: null,
  onNext: null,
};
New.propTypes = {
  data: PropTypes.shape({
    [SUB_ORGANIZATION]: PropTypes.shape({ [ID]: PropTypes.string.isRequired }),
    [DATE]: PropTypes.string,
    [DAYS]: PropTypes.number,
    [SHIFTS]: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired),
  }),
  onNext: PropTypes.func,
};

export default New;
