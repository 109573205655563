import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { reducerTemplate } from 'store/utils';
import { ID, PAGINATION, CURSOR, SORT, SORT_BY, SORT_DIR, LIMIT, SEARCH } from '.';

import { IMPORTS, FILTERS, SUCCESS_IMPORTS, ERROR_IMPORTS, CURRENT, ORGANIZATION_KINDS } from './consts';
import {
  UPDATE_NURSING_HOMES,
  UPDATE_NURSING_HOME,
  DELETE_NURSING_HOME,
  RESET_NURSING_HOMES,
  UPDATE_OPTICIANS,
  UPDATE_OPTICIAN,
  DELETE_OPTICIAN,
  RESET_OPTICIANS,
  UPDATE_CLINICS,
  UPDATE_CLINIC,
  DELETE_CLINIC,
  RESET_CLINICS,
  UPDATE_IMPORTS,
  RESET_IMPORTS,
  UPDATE_SEARCH,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTERS,
  UPDATE_CURRENT_ORGANIZATION,
} from './types';

const nurseHomesReducer = reducerTemplate(
  {
    id: ID,
    list: UPDATE_NURSING_HOMES,
    item: UPDATE_NURSING_HOME,
    deleteItem: DELETE_NURSING_HOME,
    reset: RESET_NURSING_HOMES,
  },
  {}
);

const opticiansReducer = reducerTemplate(
  {
    id: ID,
    list: UPDATE_OPTICIANS,
    item: UPDATE_OPTICIAN,
    deleteItem: DELETE_OPTICIAN,
    reset: RESET_OPTICIANS,
  },
  {}
);

const clinicsReducer = reducerTemplate(
  {
    id: ID,
    list: UPDATE_CLINICS,
    item: UPDATE_CLINIC,
    deleteItem: DELETE_CLINIC,
    reset: RESET_CLINICS,
  },
  {}
);

const importsReducer = handleActions(
  {
    [UPDATE_IMPORTS]: (_, { payload }) => payload,
    [RESET_IMPORTS]: () => ({}),
  },
  {}
);

const initFilter = {
  [SEARCH]: '',
  [SORT]: {
    [SORT_BY]: '',
    [SORT_DIR]: '',
  },
  [PAGINATION]: {
    [CURSOR]: 0,
    [LIMIT]: 10,
  },
};

const initFilters = {
  [ORGANIZATION_KINDS.NURSING_HOME]: initFilter,
  [ORGANIZATION_KINDS.OPTICIAN]: initFilter,
  [ORGANIZATION_KINDS.CLINIC]: initFilter,
  [SUCCESS_IMPORTS]: initFilter,
  [ERROR_IMPORTS]: initFilter,
};

const filtersReducer = handleActions(
  {
    [UPDATE_SEARCH]: (state, { payload }) => {
      const { kind, value } = payload;
      return {
        ...state,
        [kind]: {
          ...state[kind],
          [SEARCH]: value,
          [PAGINATION]: {
            ...state[kind][PAGINATION],
            [CURSOR]: 0,
          },
        },
      };
    },
    [UPDATE_SORT]: (state, { payload }) => {
      const { kind, value } = payload;
      return {
        ...state,
        [kind]: {
          ...state[kind],
          [SORT]: value,
          [PAGINATION]: {
            ...state[kind][PAGINATION],
            [CURSOR]: 0,
          },
        },
      };
    },
    [UPDATE_PAGINATION]: (state, { payload }) => {
      const { kind, value } = payload;
      return {
        ...state,
        [kind]: {
          ...state[kind],
          [PAGINATION]: {
            ...state[kind][PAGINATION],
            ...value,
          },
        },
      };
    },
    [RESET_FILTERS]: (state, { payload }) =>
      payload
        ? {
            ...state,
            [payload]: initFilters[payload],
          }
        : initFilters,
  },
  initFilters
);

const currentOrganizationReducer = handleActions(
  {
    [UPDATE_CURRENT_ORGANIZATION]: (state, { payload }) => payload || {},
  },
  {}
);

export default combineReducers({
  [ORGANIZATION_KINDS.NURSING_HOME]: nurseHomesReducer,
  [ORGANIZATION_KINDS.OPTICIAN]: opticiansReducer,
  [ORGANIZATION_KINDS.CLINIC]: clinicsReducer,
  [IMPORTS]: importsReducer,
  [FILTERS]: filtersReducer,
  [CURRENT]: currentOrganizationReducer,
});
