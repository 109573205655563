import React from 'react';
import PropTypes from 'prop-types';

import { Container, Item } from './styles';

const Row = ({ widths, publicId, name, birth, date }) => (
  <Container>
    <Item width={widths[0]}>{publicId}</Item>
    <Item width={widths[1]}>{name}</Item>
    <Item width={widths[2]}>{birth}</Item>
    <Item width={widths[3]}>{date}</Item>
  </Container>
);

Row.defaultProps = {
  publicId: '–',
  name: '–',
  birth: '–',
  date: '–',
};

Row.propTypes = {
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  publicId: PropTypes.string,
  name: PropTypes.string,
  birth: PropTypes.string,
  date: PropTypes.string,
};

export default Row;
