import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_NURSING_HOMES,
  UPDATE_NURSING_HOME,
  DELETE_NURSING_HOME,
  RESET_NURSING_HOMES,
  UPDATE_OPTICIANS,
  UPDATE_OPTICIAN,
  DELETE_OPTICIAN,
  RESET_OPTICIANS,
  UPDATE_CLINICS,
  UPDATE_CLINIC,
  DELETE_CLINIC,
  RESET_CLINICS,
  UPDATE_IMPORTS,
  RESET_IMPORTS,
  UPDATE_CURRENT_ORGANIZATION,
  FETCH_CURRENT_ORGANIZATION,
  CREATE_ORGANIZATION,
  FETCH_ORGANIZATIONS,
  FETCH_INIT_COLLECT_ORGANIZATIONS,
  FETCH_COLLECT_ORGANIZATIONS,
  FETCH_ORGANIZATION,
  FETCH_COLLECT_ORGANIZATION,
  UPDATE_ORGANIZATION,
  UPLOAD_EXCEL_DATA,
  UPDATE_SEARCH,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTERS,
  REMOVE_ORGANIZATION,
} from './types';

export const updateNurseHomes = createAction(UPDATE_NURSING_HOMES);
export const updateNurseHome = createAction(UPDATE_NURSING_HOME);
export const updateOpticians = createAction(UPDATE_OPTICIANS);
export const updateOptician = createAction(UPDATE_OPTICIAN);
export const updateClinics = createAction(UPDATE_CLINICS);
export const updateClinic = createAction(UPDATE_CLINIC);
export const updateImports = createAction(UPDATE_IMPORTS);
export const deleteNurseHome = createAction(DELETE_NURSING_HOME);
export const deleteOptician = createAction(DELETE_OPTICIAN);
export const deleteClinic = createAction(DELETE_CLINIC);
export const resetNurseHomes = createAction(RESET_NURSING_HOMES);
export const resetOpticians = createAction(RESET_OPTICIANS);
export const resetClinics = createAction(RESET_CLINICS);
export const resetImports = createAction(RESET_IMPORTS);
export const updateSearch = createAction(UPDATE_SEARCH);
export const updateSort = createAction(UPDATE_SORT);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const resetFilters = createAction(RESET_FILTERS);
export const updateCurrentOrganization = createAction(UPDATE_CURRENT_ORGANIZATION);

export const fetchCurrentOrganization = createAlertAction(FETCH_CURRENT_ORGANIZATION);
export const createOrganization = createAlertAction(CREATE_ORGANIZATION);
export const fetchOrganizations = createAlertAction(FETCH_ORGANIZATIONS);
export const fetchInitCollectOrganizations = createAlertAction(FETCH_INIT_COLLECT_ORGANIZATIONS);
export const fetchCollectOrganizations = createAlertAction(FETCH_COLLECT_ORGANIZATIONS);
export const fetchOrganization = createAlertAction(FETCH_ORGANIZATION);
export const fetchCollectOrganization = createAlertAction(FETCH_COLLECT_ORGANIZATION);
export const updateOrganization = createAlertAction(UPDATE_ORGANIZATION);
export const uploadExcelData = createAlertAction(UPLOAD_EXCEL_DATA);
export const removeOrganization = createAlertAction(REMOVE_ORGANIZATION);
