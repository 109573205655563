import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/FullScreenSpinner';

import { Container, Message, Wrapper, Button } from './styles';

const Warn = ({ loading, message, showActon, deleteAction, smallWidth }) => (
  <Container smallWidth={smallWidth}>
    {loading ? (
      <Spinner height="100%" />
    ) : (
      <>
        <Message>{message}</Message>
        <Wrapper smallWidth={smallWidth}>
          <Button type="button" color="warning" title="Löschen Sie" onClick={deleteAction} smallWidth={smallWidth}>
            JA
          </Button>
          <Button type="button" title="Nicht Löschen" onClick={showActon} smallWidth={smallWidth}>
            NEIN
          </Button>
        </Wrapper>
      </>
    )}
  </Container>
);

Warn.defaultProps = {
  loading: false,
  smallWidth: false,
};

Warn.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string.isRequired,
  showActon: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  smallWidth: PropTypes.bool,
};

export default Warn;
