import { PATIENT, NURSING_HOME, OPTICIAN, AGENT, EMERGENCY, FAMILY_DOCTOR, EYE_DOCTOR } from 'store/patients';

export const contactNames = [
  {
    id: PATIENT,
    title: 'Patient',
  },
  {
    id: NURSING_HOME,
    title: 'Pflegezentrum',
  },
  {
    id: OPTICIAN,
    title: 'Optiker',
  },
  {
    id: AGENT,
    title: 'Gesetzlicher Vertreter',
  },
  {
    id: EMERGENCY,
    title: 'Kontaktperson',
  },
  {
    id: FAMILY_DOCTOR,
    title: 'Hausarzt/-ärztin',
  },
  {
    id: EYE_DOCTOR,
    title: 'Augenarzt/-ärztin',
  },
];

export const tableHeadData = [
  {
    label: 'Bericht-Nr.',
    attribute: 'publicId',
    type: 'text',
    fieldWidth: 2 / 10,
  },
  {
    label: 'Empfänger',
    attribute: 'receiver.lastname',
    type: 'text',
    fieldWidth: 3 / 10,
  },
  {
    label: 'Datum',
    attribute: 'meta.modified',
    type: 'date',
    fieldWidth: 2 / 10,
  },
  {
    label: 'Status',
    attribute: 'status',
    type: 'number',
    fieldWidth: 2 / 10,
  },
  {
    label: 'PDF',
    attribute: 'url',
    type: 'text',
    fieldWidth: 1 / 10,
  },
];
