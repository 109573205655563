import { object, string, number } from 'yup';
import { isEmpty, times } from 'ramda';

import { DATE, DAYS } from 'store/planning';

const validationSchema = object({
  [DATE]: string().required('Bitte wählen Sie ein Startdatum'),
  [DAYS]: number()
    .min(1, 'Mindestens 1')
    .max(10, 'Maximal 10')
    .required('Bitte wählen Sie die Anzahl der Tage')
    .typeError('Bitte wählen Sie die Anzahl der Tage'),
});

export const validateFrom = async (values, action) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
    action(($) => (isEmpty($) ? $ : {}));
  } catch (yupErrors) {
    action(
      yupErrors.inner.reduce((acc, { path, message }) => {
        acc[path] = message;

        return acc;
      }, {})
    );
  }
};

export const getHoursAndMinutes = (dateStr) => {
  const date = new Date(dateStr);

  return [date.getHours(), date.getMinutes()].map((v) => (v < 10 ? `0${v}` : v)).join(':');
};

export const resetShifts = (date, shifts, days) => {
  const lastDate = new Date(date);

  return times((i) => {
    if (i !== 0) {
      const day = lastDate.getDay();

      lastDate.setDate(lastDate.getDate() + ((day === 5 && 3) || (day === 6 && 2) || 1));
    }

    if (shifts[i]) {
      lastDate.setHours(new Date(shifts[i][0]).getHours());
    } else {
      lastDate.setHours(i === 0 ? 10 : 9);
    }

    const result = [lastDate.toISOString()];

    lastDate.setHours(shifts[i] ? new Date(shifts[i][1]).getHours() : 17);
    result.push(lastDate.toISOString());

    return result;
  }, days || shifts.length);
};
