import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { sum } from 'ramda';
import PropTypes from 'prop-types';

import { usePlanningActions, useAlerts, ID, FILE } from 'store/planning';
import { useAsyncState } from 'utils/useAsyncState';
import planningTemplate from 'assets/planningTemplate.xlsx';
import Button from 'components/Button';
import Spinner from 'components/FullScreenSpinner';
import Section from '../../Section';
import UploadXLS from '../UploadXLS';

import { getWardStructure } from './utils';
import UploadMultiPDF from './UploadMultiPDF';
import { Wrapper, SelectBtn, Link } from './styles';

const Step2 = ({ setStep, xlsFile, setXlsFile }) => {
  const { id } = useParams();
  const [type, setType] = useState(null);
  const [formMedia, setFormMedia] = useAsyncState([]);
  const updateType = useCallback(({ currentTarget }) => setType(currentTarget.value), []);
  const { fetchScheduleForms, createScheduleXML, createScheduleForms } = usePlanningActions();
  const { extractId, loading, error, errors } = useAlerts();
  const { action: getMedia, loading: getMediaLoading, success: getMediaSuccess } = useAlerts(fetchScheduleForms);
  const onBack = useCallback(() => setStep(1), [setStep]);
  const onNext = useCallback(() => {
    if (type === 'xls' && xlsFile) extractId(createScheduleXML({ [ID]: id, [FILE]: xlsFile }));
    if (type === 'pdf' && formMedia.length) extractId(createScheduleForms(id));
  }, [createScheduleForms, createScheduleXML, extractId, xlsFile, formMedia.length, id, type]);
  const isPDFDisabled = useMemo(
    () => type === 'pdf' && sum(formMedia.map(({ items }) => items.length || 0)) === 0,
    [formMedia, type]
  );
  const fetchMedia = useCallback(() => getMedia(id), [getMedia, id]);

  useEffect(() => {
    fetchMedia();
  }, [fetchMedia]);

  useEffect(() => {
    if (getMediaSuccess) {
      setFormMedia(getWardStructure(getMediaSuccess));
      setType(getMediaSuccess.length ? 'pdf' : 'xls');
    }
  }, [getMediaSuccess, setFormMedia]);

  return (
    <>
      <Section title="Wie möchten Sie die Patienten importieren?" current={2} amount={2} withCounter />
      <Wrapper>
        <SelectBtn type="button" value="xls" onClick={updateType} disabled={type === 'xls' || getMediaLoading}>
          Via Excel-Template
        </SelectBtn>
        <SelectBtn type="button" value="pdf" onClick={updateType} disabled={type === 'pdf' || getMediaLoading}>
          Via Einverständniserklärung (PDF-Scans)
        </SelectBtn>
      </Wrapper>
      {type === 'pdf' && (
        <UploadMultiPDF
          files={formMedia}
          updateFiles={setFormMedia}
          refetchMedias={fetchMedia}
          refetchLoading={getMediaLoading}
        />
      )}
      {type === 'xls' && (
        <UploadXLS label="Patientenliste" value={xlsFile} onChange={setXlsFile} loading={loading} error={error} errors={errors} />
      )}
      {!type && getMediaLoading && <Spinner height="200px" />}
      <Wrapper>
        <Button type="button" onClick={onBack} height="40px">
          Zurück
        </Button>
        <Button
          type="button"
          onClick={onNext}
          height="40px"
          color="normal"
          disabled={!type || (type === 'xls' && !xlsFile) || isPDFDisabled || getMediaLoading}
        >
          Importieren
        </Button>
      </Wrapper>
      {type === 'xls' && (
        <Link href={planningTemplate} download="Template herunterladen" target="_blank" rel="noopener noreferrer">
          Template herunterladen
        </Link>
      )}
    </>
  );
};

Step2.defaultProps = { xlsFile: null };
Step2.propTypes = {
  setStep: PropTypes.func.isRequired,
  xlsFile: PropTypes.instanceOf(File),
  setXlsFile: PropTypes.func.isRequired,
};

export default Step2;
