import moment from 'moment';
import { unapply, map, pick, apply, equals, pipe, toLower, pathOr, when, is } from 'ramda';

import { ASC, DESC } from 'api/consts';

const getFullName = (firstName, lastName) => {
  const spacedLastName = lastName ? ` ${lastName}` : '';

  return !firstName && !lastName ? 'Neuer Patient' : `${firstName}${spacedLastName}`;
};

export const extractImage = (images) => images.filter(({ mimetype }) => mimetype.includes('image'));

export const compareProps = (arrayPropsNames) =>
  pipe(
    unapply(($) => $),
    map(pick(arrayPropsNames)),
    apply(equals)
  );

const formatDate = new Intl.DateTimeFormat('de', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

const formatTime = new Intl.DateTimeFormat('de', {
  hour: '2-digit',
  minute: '2-digit',
});

const formatDateWithTime = new Intl.DateTimeFormat('de', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
});

export const parseDate = (date) => (date ? formatDate.format(new Date(date)) : '');
export const parseTime = (date) => (date ? formatTime.format(new Date(date)) : '');
export const parseDateWithTime = (date) => (date ? formatDateWithTime.format(new Date(date)) : '');
export const parseUTCDate = (date) => {
  if (!date) return null;

  const d = new Date(date);

  return new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
};

export const parseBirthday = (date) => (date ? moment.utc(date).format('DD.MM.YYYY') : '');
export const getHeadTitleOfPatient = (firstName, lastName, birthday, publicId) =>
  `${getFullName(firstName, lastName)}, ${parseBirthday(birthday)} ${publicId ? `(${publicId})` : ''}`;

const getSortValue = (sortPath, isDate) =>
  pipe(
    pathOr('', sortPath),
    when(
      (val) => isDate && val,
      (val) => new Date(val).valueOf()
    ),
    when(is(String), toLower())
  );
export const handleStaticSort = (sortBy, sortDir, list, isDate) => {
  if (!(sortBy && sortDir)) return list || [];

  const getValue = getSortValue(sortBy.split('.'), isDate);

  return [...(list || [])].sort((a = {}, b = {}) => {
    const val1 = getValue(a);
    const val2 = getValue(b);

    if (sortDir === ASC) return val1 > val2 ? 1 : -1;
    if (sortDir === DESC) return val1 < val2 ? 1 : -1;

    return 0;
  });
};

const maxDayInMonths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const validateDate = (day, month, year) => {
  if (!(day && month && year)) return false;
  if (year < 1000) return false;
  if (month < 1 || month > 12) return false;
  if (day > 28 && month === 2 && year % 4 !== 0) return false;
  if (day < 1 || day > maxDayInMonths[month - 1]) return false;

  return true;
};

export const validateTime = (hour, minute) => {
  if (hour < 0 || hour > 23) return false;
  if (minute < 0 || minute > 59) return false;

  return true;
};
