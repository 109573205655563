import { object, string, number } from 'yup';

import { SUB_ORGANIZATION, ID, DATE, DAYS, SHIFTS } from 'store/planning';

const getCorrectDate = () => {
  const d = new Date();
  const day = d.getDay();

  return new Date(d.getFullYear(), d.getMonth(), d.getDate() + ((day === 6 && 2) || (day === 0 && 1) || 0)).toISOString();
};
const getDefaultShifts = (date) => {
  const shift = [];
  const d = new Date(date);

  d.setHours(10);
  d.setMinutes(0);
  shift.push(d.toISOString());
  d.setHours(17);
  shift.push(d.toISOString());

  return [shift];
};

export const getInitialValues = (data) => {
  const date = data[DATE] || getCorrectDate();

  return {
    [SUB_ORGANIZATION]: data[SUB_ORGANIZATION] || null,
    [DATE]: date,
    [DAYS]: data[DAYS] ?? 1,
    [SHIFTS]: data[SHIFTS] || getDefaultShifts(date),
  };
};

export const validationSchema = object({
  [SUB_ORGANIZATION]: object({
    [ID]: string().required('Pflegeheim ist erforderlich'),
  }).typeError('Pflegeheim ist erforderlich'),
  [DATE]: string().required('Bitte wählen Sie ein Startdatum'),
  [DAYS]: number().min(1, 'Mindestens 1').max(10, 'Maximal 10').required('Bitte wählen Sie die Anzahl der Tage'),
});
