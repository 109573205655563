import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { usePlanningActions, useAlerts } from 'store/planning';
import Download from 'assets/svg-icons/Download';
import Spinner from 'components/FullScreenSpinner';
import { STATUSES } from '../../consts';

import { Container, Text, Button, IconWrap } from './styles';

const Row = ({ id, widths, date, days, amountPatients, organization, editor, status }) => {
  const { exportSchedule } = usePlanningActions();
  const { action, loading, error } = useAlerts(exportSchedule);

  const { label, color } = useMemo(() => STATUSES[status] || {}, [status]);

  const onLinkClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      action(id);
    },
    [action, id]
  );

  return (
    <Container to={`/planning/${id}`} onClick={loading ? onLinkClick : undefined}>
      <Text $width={widths[0]} $align="center">
        {date}
      </Text>
      <Text $width={widths[1]} $align="center">
        {days}
      </Text>
      <Text $width={widths[2]} $align="center">
        {amountPatients}
      </Text>
      <Text $width={widths[3]}>{organization}</Text>
      <Text $width={widths[4]} $fontSize={13}>
        {editor}
      </Text>
      <Text $width={widths[5]} $fontSize={10} $color={color}>
        {label || '-'}
      </Text>
      <Button onClick={onClick} disabled={loading} $width={widths[6]} title="Download XLS">
        {loading ? (
          <Spinner size={46} height="100%" />
        ) : (
          <IconWrap $error={error}>
            <Download />
          </IconWrap>
        )}
      </Button>
    </Container>
  );
};

Row.defaultProps = { organization: '–', editor: '–' };

Row.propTypes = {
  id: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  date: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired,
  amountPatients: PropTypes.number.isRequired,
  organization: PropTypes.string,
  editor: PropTypes.string,
  status: PropTypes.number.isRequired,
};

export default Row;
