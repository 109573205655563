import styled from 'styled-components';

export const Container = styled.div`
  border-top: solid 1px var(--color-grey97);
  margin-top: 12px;
  padding-top: 12px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-right: 5px;
  cursor: pointer;
`;

export const Label = styled.label`
  width: 240px;
  text-align: left;
  color: var(--color-grey6A);
  font-weight: var(--font-weight500);
  font-size: var(--font-size14);
  padding-right: 10px;
`;

export const ItemContent = styled.div`
  margin: 0;
  padding: 30px 0;
`;
