import React, { useCallback, useMemo } from 'react';
import { prop, isEmpty } from 'ramda';
import PropTypes from 'prop-types';

import AnimateBox from 'components/AnimateBox';
import Table from 'components/Table';
import FileDropZone from 'components/FileDropZone';
import Message from 'components/Message';
import {
  ID,
  SUCCESS_IMPORTS,
  ERROR_IMPORTS,
  EXCEL_TEMPLATE_URL,
  useOrganizationActions,
  useAlerts,
  useImports,
} from 'store/organizations';
import { EXAMINATION, usePermissions } from 'permissions';

import Button from './Button';
import Row from './Row';
import { tableHead, handleImports } from './utils';
import { Title, TableTitle } from './styles';

const ImportExamination = ({ organization }) => {
  const can = usePermissions();
  const { list: successImports, sort: successImportsSort } = useImports(SUCCESS_IMPORTS);
  const { list: errorImports, sort: errorImportsSort } = useImports(ERROR_IMPORTS);
  const { extractId, error, loading } = useAlerts();
  const { uploadExcelData, updateSort } = useOrganizationActions();
  const successList = useMemo(() => handleImports(successImports), [successImports]);
  const errorList = useMemo(() => handleImports(errorImports), [errorImports]);
  const isOpen = !(isEmpty(successImports) && isEmpty(errorImports));

  const updateSuccessImportsSort = useCallback(
    (value) => {
      updateSort({ kind: SUCCESS_IMPORTS, value });
    },
    [updateSort]
  );
  const updateErrorImportsSort = useCallback(
    (value) => {
      updateSort({ kind: ERROR_IMPORTS, value });
    },
    [updateSort]
  );

  const renderRow = useCallback((props) => <Row {...props} />, []);
  const organizationId = useMemo(() => prop(ID, organization), [organization]);

  const handleDownLoad = useCallback(() => {
    window.open(EXCEL_TEMPLATE_URL);
  }, []);

  const onDrop = useCallback(
    (files) => {
      if (loading || !files.length) return;

      extractId(uploadExcelData({ files, organizationId }));
    },
    [extractId, loading, organizationId, uploadExcelData]
  );

  return (
    can.create(EXAMINATION) && (
      <>
        <Title>Template herunterladen</Title>
        <Button type="button" color="normal" onClick={handleDownLoad}>
          herunterladen
        </Button>
        <Title>Untersuchungen hochladen</Title>
        <FileDropZone
          onDrop={onDrop}
          accept={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
          loading={loading}
          label="Excel-Datei in dieses"
          acceptFormats={['.xls', '.xlsx']}
        />
        {error && <Message type="error">{error}</Message>}
        <AnimateBox isOpen={isOpen}>
          {Boolean(successImports.length) && (
            <>
              <TableTitle>Erfolgreich importierte Untersuchungen</TableTitle>
              <Table
                head={tableHead}
                list={successList}
                renderRow={renderRow}
                sort={successImportsSort}
                updateSort={updateSuccessImportsSort}
              />
            </>
          )}
          {Boolean(errorImports.length) && (
            <>
              <TableTitle error>Nicht importierte Untersuchungen</TableTitle>
              <Table
                head={tableHead}
                list={errorList}
                renderRow={renderRow}
                sort={errorImportsSort}
                updateSort={updateErrorImportsSort}
              />
            </>
          )}
        </AnimateBox>
      </>
    )
  );
};

ImportExamination.propTypes = {
  organization: PropTypes.shape({
    [ID]: PropTypes.string.isRequired,
  }).isRequired,
};

export default ImportExamination;
