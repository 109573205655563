import { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { map, applySpec, prop, pipe, props, propOr, when, is, cond, equals, T, ifElse, pathOr, not, all } from 'ramda';

import {
  useCurrentSchedule,
  usePlanningActions,
  useAlerts,
  SUB_ORGANIZATION,
  NAME,
  REGISTRATIONS,
  CONFIDENCE,
  WARD,
  SHIFTS,
  DIABETES,
  REPRESENTATIVE,
  SUB_EYE_DOCTOR,
  SUB_GP,
  SUB_FORM,
} from 'store/planning';
import {
  ID,
  PUBLIC_ID,
  DATE,
  LAST_NAME,
  FIRST_NAME,
  BIRTH,
  STATUS,
  SUB_PATIENT,
  EXPAND,
  META,
  EDITOR,
  EYE_DOCTOR,
  FAMILY_DOCTOR,
  CONTACTS,
} from 'store/patients';
import { parseDate } from 'utils';
import { SUB_EXAMINATION, PROGRESS } from 'store/examinations';

const hasMatches = pipe(
  props([SUB_PATIENT, STATUS, CONFIDENCE]),
  ([patient, status, confidence]) => is(Object, patient) && (status === 3 || status === 2 || (status === 1 && confidence === 1))
);

const getPatientObject = when(hasMatches, prop(SUB_PATIENT));

export const handleStatus = ({ [STATUS]: status, [CONFIDENCE]: confidence }) =>
  cond([
    [equals(3), () => ({ label: 'Neu erstellt', color: 'var(--color-green50)' })],
    [equals(2), () => ({ label: 'Bestätigt', color: 'var(--color-green50)' })],
    [
      equals(1),
      (_, conf) => {
        const percents = Math.round(conf * 100);

        return {
          label: `Gefunden (${percents}%)`,
          color:
            (percents === 100 && 'var(--color-green50)') || (percents <= 50 && 'var(--color-redED)') || 'var(--color-orangeDC)',
        };
      },
    ],
    [equals(0), () => ({ label: 'Nicht gefunden', color: 'var(--color-redED)' })],
    [T, () => ({ label: '-', color: 'var(--color-grey6A)' })],
  ])(status, confidence);

export const useData = (sort) => {
  const { id } = useParams();
  const [scheduleId, organization, date, registrations, status, shifts] = useCurrentSchedule([
    ID,
    SUB_ORGANIZATION,
    DATE,
    REGISTRATIONS,
    STATUS,
    SHIFTS,
  ]);
  const { fetchSchedule, resetCurrent } = usePlanningActions();
  const { action, loading, error } = useAlerts(fetchSchedule);
  const patients = useMemo(
    () =>
      map(
        applySpec({
          id: prop(ID),
          patient: prop(SUB_PATIENT),
          isMatched: hasMatches,
          organization: prop(SUB_ORGANIZATION),
          publicId: pipe(getPatientObject, prop(PUBLIC_ID)),
          date: prop(DATE),
          firstName: pipe(getPatientObject, prop(FIRST_NAME)),
          lastName: pipe(getPatientObject, prop(LAST_NAME)),
          birth: pipe(getPatientObject, prop(BIRTH)),
          ward: prop(WARD),
          status: prop(STATUS),
          confidence: prop(CONFIDENCE),
          statusData: handleStatus,
          diabetes: propOr(null, DIABETES),
          represent: ifElse(hasMatches, pathOr({}, [SUB_PATIENT, CONTACTS, REPRESENTATIVE]), propOr({}, REPRESENTATIVE)),
          eyeDoctor: ifElse(
            hasMatches,
            pipe(
              pathOr({}, [SUB_PATIENT, EYE_DOCTOR]),
              when(pipe(prop(SUB_ORGANIZATION), is(String), not), () => null)
            ),
            prop(SUB_EYE_DOCTOR)
          ),
          gp: ifElse(
            hasMatches,
            pipe(
              pathOr({}, [SUB_PATIENT, FAMILY_DOCTOR]),
              when(pipe(prop(SUB_ORGANIZATION), is(String), not), () => null)
            ),
            prop(SUB_GP)
          ),
          registrationForm: prop('_form'),
          isBlocked: pathOr(false, [SUB_EXAMINATION, PROGRESS, 0, 'done']),
        })
      )(registrations || []),
    [registrations]
  );

  useEffect(() => {
    action({
      [ID]: id,
      [EXPAND]: [
        SUB_ORGANIZATION,
        `${META}.${EDITOR}`,
        `${REGISTRATIONS}.${SUB_PATIENT}`,
        `${REGISTRATIONS}.${SUB_ORGANIZATION}`,
        `${REGISTRATIONS}.${SUB_EYE_DOCTOR}`,
        `${REGISTRATIONS}.${SUB_GP}`,
        `${REGISTRATIONS}.${SUB_EXAMINATION}`,
        `${REGISTRATIONS}.${SUB_FORM}`,
      ],
      ...sort,
    });
  }, [action, id, sort]);

  useEffect(() => resetCurrent, [resetCurrent]);

  return {
    isReady: Boolean(scheduleId),
    isLoading: loading,
    isAllBlocked: useMemo(() => patients.length > 0 && all(prop('isBlocked'), patients), [patients]),
    dataError: !scheduleId && error,
    status,
    title: useMemo(() => [propOr('', NAME, organization), parseDate(date)].filter(Boolean).join(', '), [date, organization]),
    shifts,
    patients,
    patientsObject: useMemo(
      () =>
        patients.reduce((acc, patient) => {
          acc[patient.id] = patient;
          return acc;
        }, {}),
      [patients]
    ),
  };
};
