import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { find, propSatisfies, includes, ifElse, propEq, init } from 'ramda';
import PropTypes from 'prop-types';

import ExaminationPanel from 'components/ExaminationPanel';
import PersonalInformationForm from 'containers/PersonalInformationForm';
import MedicalHistoryForm from 'containers/MedicalHistoryForm';
import EyeAnalysisForm from 'containers/EyeAnalysisForm';
import RecommendationForm from 'containers/RecommendationForm';
import InvoicesForm from 'containers/InvoicesForm';
import PatientInvoices from 'containers/PatientInvoices';
import { PERSONAL, MEDICAL_HISTORY, ANALYSIS, RECOMMEND, INVOICE, REPORT, BILLS, usePermissions } from 'permissions';
import { useInfoBoxPath, usePatientReportsPath } from '../hooks';
import Navigation from '../Navigation';

function PhysicianPatient({ readMode }) {
  const can = usePermissions();
  const { id, view = PERSONAL } = useParams();
  const infoBoxPath = useInfoBoxPath();
  const patientReportsPath = usePatientReportsPath();

  const tabs = useMemo(
    () => [
      {
        id: 'personalInfoTab',
        path: `/patients/${id}/${PERSONAL}`,
        label: 'Persönliche Angaben',
        content: PersonalInformationForm,
      },
      {
        id: 'medicalHistoryTab',
        path: `/patients/${id}/${MEDICAL_HISTORY}`,
        label: 'Anamnese',
        content: MedicalHistoryForm,
      },
      {
        id: 'multiTab',
        path: `/patients/${id}/${ANALYSIS}`,
        label: 'Untersuch',
        content: MedicalHistoryForm,
      },
      {
        id: 'invoicesTab',
        path: `/patients/${id}/${BILLS}`,
        label: 'Rechnungen',
        content: PatientInvoices,
      },
      ...(can.read(REPORT)
        ? [
            {
              id: 'patientReportsTab',
              path: patientReportsPath,
              label: 'Berichte',
            },
          ]
        : []),
      {
        id: 'infoBoxTab',
        path: infoBoxPath,
        label: 'Infobox',
      },
    ],
    [id, infoBoxPath, patientReportsPath, can]
  );

  const subTabs = useMemo(
    () => [
      {
        id: 'analysisTab',
        path: `/patients/${id}/${ANALYSIS}`,
        label: 'Befundung',
        content: EyeAnalysisForm,
      },
      {
        id: 'recommendationTab',
        path: `/patients/${id}/${RECOMMEND}`,
        label: 'Diagnose',
        content: RecommendationForm,
      },
      {
        id: 'invoiceTab',
        path: `/patients/${id}/${INVOICE}`,
        label: 'Rechnung',
        content: InvoicesForm,
      },
    ],
    [id]
  );

  const activeSubTab = useMemo(() => find(propSatisfies(includes(view), 'path'), subTabs), [view, subTabs]);
  const activeTab = useMemo(
    () =>
      ifElse(
        () => Boolean(activeSubTab),
        find(propEq('multiTab', 'id')),
        find(propSatisfies(includes(view), 'path'))
      )(init(tabs)) || tabs[0],
    [view, tabs, activeSubTab]
  );

  const Component = activeSubTab?.content || activeTab?.content;

  return (
    <>
      <Navigation tabs={tabs} active={activeTab.id} />
      {activeSubTab && (
        <>
          <Navigation tabs={subTabs} active={activeSubTab.id} small />
          <ExaminationPanel />
        </>
      )}
      {Component && <Component readMode={readMode} />}
    </>
  );
}

PhysicianPatient.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default PhysicianPatient;
