import styled from 'styled-components';

import Btn from 'components/Button';

const Button = styled(Btn)`
  color: var(--color-white);
  height: 40px;
`;

export default Button;
