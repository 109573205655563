import { useMemo, useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { head, when, pipe, length, equals } from 'ramda';

import { UPDATE, READ, useReadModeItems } from 'permissions';
import { INFO_BOX } from 'store/infoItems';
import { PATIENT_REPORTS } from 'store/patients';

export const useReadMode = (id, status, view) => {
  const readModeItems = useReadModeItems(status, view);
  const defaultItem = useMemo(() => head(readModeItems), [readModeItems]);
  const [state, setState] = useState(defaultItem);
  const canWrite = useMemo(() => readModeItems.includes(UPDATE), [readModeItems]);

  const switchReadMode = useCallback(() => {
    when(pipe(length, equals(2)), (items) => setState(state === items[0] ? items[1] : items[0]), readModeItems);
  }, [readModeItems, state]);

  useEffect(() => {
    setState(defaultItem);
  }, [defaultItem, id]);

  return {
    error: !state,
    isWrite: state === UPDATE,
    isRead: state === READ,
    canWrite,
    switchReadMode,
  };
};

export const useInfoBoxPath = () => {
  const { pathname, hash } = useLocation();

  return useMemo(() => (hash === `#${INFO_BOX}` ? pathname : `${pathname}#${INFO_BOX}`), [hash, pathname]);
};

export const usePatientReportsPath = () => {
  const { pathname, hash } = useLocation();

  return useMemo(() => (hash === `#${PATIENT_REPORTS}` ? pathname : `${pathname}#${PATIENT_REPORTS}`), [hash, pathname]);
};
