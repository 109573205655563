import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Container, Item, Status } from './styles';

const Row = ({ id, patientId, widths, publicId, name, birth, date, status, color }) => {
  const { push } = useHistory();
  const handleRowClick = useCallback(
    ({ currentTarget }) => {
      const { patient, examination } = currentTarget?.dataset || {};

      if (patient && examination) {
        push(`/patients/${patient}/personal?exam=${examination}`, { previousPath: '/patients' });
      }
    },
    [push]
  );

  return (
    <Container type="button" data-examination={id} data-patient={patientId} onClick={handleRowClick}>
      <Item width={widths[0]}>{publicId}</Item>
      <Item width={widths[1]}>{name}</Item>
      <Item width={widths[2]}>{birth}</Item>
      <Item width={widths[3]}>{date}</Item>
      {widths[4] && (
        <Status width={widths[4]} color={color}>
          {status}
        </Status>
      )}
    </Container>
  );
};

Row.defaultProps = {
  birth: '',
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  publicId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  birth: PropTypes.string,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Row;
