import React, { useCallback, useMemo } from 'react';
import { useFormikContext, useField } from 'formik';
import { tail, assocPath, pathOr } from 'ramda';
import PropTypes from 'prop-types';

import TextInput from 'components/TextInputNew';
import Checkbox from 'components/CheckboxNew';
import Select from 'components/SelectNew';
import Radios from 'components/RadiosNew';
import AddressAutocomplete from 'components/AddressAutocomplete';
import { POSITION_TITLES, GENDER_RADIOS } from 'utils/constants';
import {
  REPORTER,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  GENDER,
  ADDRESS,
  CANTON,
  COUNTRY,
  ZIP_CODE,
  CITY,
  PHONE,
  EMAIL,
  COPY_FROM,
  INSTITUTION,
} from 'store/patients';

const Contact = ({ allowCopy, readMode, correspondenceLabel, copyFrom, feature, errors }) => {
  const getPath = useCallback((name) => [...feature, name].join('.'), [feature]);
  const { setValues } = useFormikContext();
  const [{ value: copyFormValue }, , { setValue: setCopyForm }] = useField(getPath(COPY_FROM));
  const [{ value: reporter }, , { setValue: setReporter }] = useField(getPath(REPORTER));
  const [{ value: title }, , { setValue: setTitle }] = useField(getPath(TITLE));
  const [firstNameField] = useField(getPath(FIRST_NAME));
  const [lastNameField] = useField(getPath(LAST_NAME));
  const [institutionField] = useField(getPath(INSTITUTION));
  const [{ value: gender }, , { setValue: setGender }] = useField(getPath(GENDER));
  const [{ value: address }] = useField(getPath(ADDRESS));
  const [{ value: zipCode }] = useField(getPath(ZIP_CODE));
  const [{ value: city }] = useField(getPath(CITY));
  const [{ value: canton }] = useField(getPath(CANTON));
  const [{ value: country }] = useField(getPath(COUNTRY));
  const [phoneField] = useField(getPath(PHONE));
  const [emailField] = useField(getPath(EMAIL));
  const updateAutocompleteValues = useCallback(
    (data) => setValues(($) => assocPath(feature, { ...pathOr({}, feature, $), ...data }, $)),
    [feature, setValues]
  );

  const isCopy = useMemo(() => Boolean(copyFormValue), [copyFormValue]);
  const createCopy = useCallback(() => {
    if (!readMode) {
      setCopyForm(copyFormValue ? null : copyFrom.feature);
    }
  }, [readMode, setCopyForm, copyFormValue, copyFrom.feature]);

  const options = useMemo(() => (title ? POSITION_TITLES : tail(POSITION_TITLES)), [title]);

  const handleReporter = useCallback(() => {
    if (!readMode) {
      setReporter(!reporter);
    }
  }, [readMode, reporter, setReporter]);

  return (
    <>
      {allowCopy && (
        <Checkbox label={copyFrom.name} selected={Boolean(copyFormValue)} onClick={createCopy} readMode={readMode} noLabel />
      )}
      {isCopy || (
        <>
          <Checkbox label={correspondenceLabel} selected={reporter} onClick={handleReporter} readMode={readMode} noLabel />
          <Select label="Titel" value={title || ''} options={options} onSelect={setTitle} readMode={readMode} />
          <TextInput label="Vorname" {...firstNameField} readMode={readMode} />
          <TextInput label="Nachname" {...lastNameField} readMode={readMode} />
          <TextInput
            label="Institution"
            {...institutionField}
            readMode={readMode}
            error={!readMode && errors && errors[INSTITUTION]}
          />
          <Radios value={gender} label="Geschlecht" items={GENDER_RADIOS} setValue={setGender} readMode={readMode} />
          <AddressAutocomplete
            address={address}
            zipCode={zipCode}
            city={city}
            canton={canton}
            country={country}
            setData={updateAutocompleteValues}
            zipCodeErr={errors && errors[ZIP_CODE]}
            readMode={readMode}
          />
          <TextInput label="Telefon" {...phoneField} readMode={readMode} />
          <TextInput label="E-Mail" {...emailField} readMode={readMode} error={!readMode && errors && errors[EMAIL]} />
        </>
      )}
    </>
  );
};

Contact.defaultProps = {
  allowCopy: false,
  correspondenceLabel: 'Erhält Korrespondenz',
  copyFrom: {},
  errors: null,
};

Contact.propTypes = {
  allowCopy: PropTypes.bool,
  readMode: PropTypes.bool.isRequired,
  correspondenceLabel: PropTypes.string,
  copyFrom: PropTypes.shape({
    feature: PropTypes.string,
    name: PropTypes.string,
  }),
  feature: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  errors: PropTypes.shape({
    [EMAIL]: PropTypes.string,
    [INSTITUTION]: PropTypes.string,
    [ZIP_CODE]: PropTypes.string,
  }),
};

export default Contact;
