import styled from 'styled-components';

export const Title = styled.p`
  font-size: var(--font-size18);
  line-height: 42px;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
  margin-top: 60px;
`;

export const TableTitle = styled.h1`
  font-size: var(--font-size15);
  color: ${({ error }) => (error ? 'var(--color-redED)' : 'var(--color-green65)')};
  border-top: 1px solid ${({ error }) => (error ? 'var(--color-redED)' : 'var(--color-green65)')};
  padding-top: 5px;
  margin: 50px 0 30px 0;
`;
