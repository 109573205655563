import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';

import { parseDate } from 'utils';
import TextInput from 'components/TextInputNew';

import { validateDate } from './utils';
import { Container, Wrapper, Message } from './styles';

const DateInput = ({ value, onChange, setValidationError, readMode, disabled }) => {
  const [dayRef, monthRef, yearRef] = [useRef(null), useRef(null), useRef(null)];
  const [day, month, year] = useMemo(() => {
    const date = new Date(value);

    return [date.getDate(), date.getMonth() + 1, date.getFullYear()];
  }, [value]);
  const [state, setState] = useState({ day, month, year, errors: {} });
  const textValue = useMemo(() => (readMode && parseDate(value)) || '', [readMode, value]);

  const onFocus = useCallback(({ currentTarget }) => currentTarget.select(), []);

  const handleChange = useCallback(
    ({ currentTarget }) => {
      const { name, value: val } = currentTarget;

      const currentDay = Number(name === 'day' ? val : dayRef.current.value);
      const currentMonth = Number(name === 'month' ? val : monthRef.current.value);
      const currentYear = Number(name === 'year' ? val : yearRef.current.value);

      const errs = validateDate(currentDay, currentMonth, currentYear);

      setState(($) => ({ ...$, [name]: Number(val), errors: errs }));
      if (isEmpty(errs)) {
        onChange(new Date(currentYear, currentMonth - 1, currentDay).toISOString());
      }
    },
    [dayRef, monthRef, yearRef, onChange]
  );

  useEffect(() => {
    setValidationError(($) => ($ === state.errors.message ? $ : state.errors.message));
  }, [setValidationError, state.errors.message]);

  return readMode ? (
    <Wrapper>
      <TextInput readMode={readMode} value={textValue} mb={0} />
    </Wrapper>
  ) : (
    <Container>
      <TextInput
        ref={dayRef}
        name="day"
        type="number"
        placeholder="DD"
        value={state.day}
        onChange={handleChange}
        error={state.errors.day}
        disabled={disabled}
        onFocus={onFocus}
        mb={0}
        min={1}
        max={31}
        step={1}
      />
      <TextInput
        ref={monthRef}
        name="month"
        type="number"
        placeholder="MM"
        value={state.month}
        onChange={handleChange}
        error={state.errors.month}
        disabled={disabled}
        onFocus={onFocus}
        mb={0}
        min={1}
        max={12}
        step={1}
      />
      <TextInput
        ref={yearRef}
        name="year"
        type="number"
        placeholder="YYYY"
        value={state.year}
        onChange={handleChange}
        error={state.errors.year}
        disabled={disabled}
        onFocus={onFocus}
        mb={0}
        min={1870}
        max={Infinity}
        step={1}
      />
      {state.errors.message && <Message>{state.errors.message}</Message>}
    </Container>
  );
};

DateInput.defaultProps = {
  value: null,
  onChange: () => null,
  setValidationError: () => null,
  readMode: false,
  disabled: false,
};
DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  setValidationError: PropTypes.func,
  readMode: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DateInput;
