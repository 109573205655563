import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_NURSING_HOMES = getName('UPDATE_NURSING_HOMES');
export const UPDATE_NURSING_HOME = getName('UPDATE_NURSING_HOME');
export const DELETE_NURSING_HOME = getName('DELETE_NURSING_HOME');
export const RESET_NURSING_HOMES = getName('RESET_NURSING_HOMES');

export const UPDATE_OPTICIANS = getName('UPDATE_OPTICIANS');
export const UPDATE_OPTICIAN = getName('UPDATE_OPTICIAN');
export const DELETE_OPTICIAN = getName('DELETE_OPTICIAN');
export const RESET_OPTICIANS = getName('RESET_OPTICIANS');

export const UPDATE_CLINICS = getName('UPDATE_CLINICS');
export const UPDATE_CLINIC = getName('UPDATE_CLINIC');
export const DELETE_CLINIC = getName('DELETE_CLINIC');
export const RESET_CLINICS = getName('RESET_CLINICS');

export const UPDATE_IMPORTS = getName('UPDATE_IMPORTS');
export const RESET_IMPORTS = getName('RESET_IMPORTS');

export const UPDATE_SEARCH = getName('UPDATE_SEARCH');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const RESET_FILTERS = getName('RESET_FILTERS');

export const UPDATE_CURRENT_ORGANIZATION = getName('UPDATE_CURRENT_ORGANIZATION');

export const FETCH_CURRENT_ORGANIZATION = getName('FETCH_CURRENT_ORGANIZATION');
export const CREATE_ORGANIZATION = getName('CREATE_ORGANIZATION');
export const FETCH_ORGANIZATIONS = getName('FETCH_ORGANIZATIONS');
export const FETCH_INIT_COLLECT_ORGANIZATIONS = getName('FETCH_INIT_COLLECT_ORGANIZATIONS');
export const FETCH_COLLECT_ORGANIZATIONS = getName('FETCH_COLLECT_ORGANIZATIONS');
export const FETCH_ORGANIZATION = getName('FETCH_ORGANIZATION');
export const FETCH_COLLECT_ORGANIZATION = getName('FETCH_COLLECT_ORGANIZATION');
export const UPDATE_ORGANIZATION = getName('UPDATE_ORGANIZATION');
export const UPLOAD_EXCEL_DATA = getName('UPLOAD_EXCEL_DATA');
export const REMOVE_ORGANIZATION = getName('REMOVE_ORGANIZATION');
