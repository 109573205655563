import { handleActions } from 'redux-actions';
import { propOr, prop } from 'ramda';

import { getPermissions } from 'permissions/utils';
import { TOKEN, TOKEN_2FA } from 'api/consts';

import { AUTHENTICATE, AUTHENTICATE_2FA, UPDATE_USER, LOGOUT, UPDATE_2FA_LINK, UPDATE_USER_DATA } from './types';
import { AUTH, AUTH_2F, HAS_2F, LOGGED_IN, LINK_2FA, USER, PERMISSIONS } from './consts';

const getInit = () => ({
  [AUTH]: Boolean(prop(TOKEN, sessionStorage)),
  [AUTH_2F]: Boolean(prop(TOKEN_2FA, sessionStorage)),
  [HAS_2F]: false,
  [LOGGED_IN]: false,
  [USER]: {},
  [PERMISSIONS]: {},
});

const handleUserData = (user = {}) => {
  const has2FA = prop(HAS_2F, user);
  const isLoggedIn = has2FA ? Boolean(prop(TOKEN_2FA, sessionStorage)) : Boolean(prop(TOKEN, sessionStorage));

  return {
    [HAS_2F]: has2FA,
    [LOGGED_IN]: isLoggedIn,
    [USER]: user,
    [PERMISSIONS]: isLoggedIn ? getPermissions(propOr([], 'roles', user)) : {},
  };
};

export default handleActions(
  {
    [AUTHENTICATE]: (state, { payload }) => {
      const token = prop('token', payload);
      window.sessionStorage.setItem(TOKEN, token);
      window.sessionStorage.removeItem(TOKEN_2FA);

      return {
        ...state,
        [AUTH]: Boolean(token),
        [AUTH_2F]: null,
        ...handleUserData(payload),
      };
    },
    [AUTHENTICATE_2FA]: (state, { payload }) => {
      const token = prop('token', payload);
      window.sessionStorage.removeItem(TOKEN);
      window.sessionStorage.setItem(TOKEN_2FA, token);

      return {
        ...state,
        [AUTH]: null,
        [AUTH_2F]: Boolean(token),
        ...handleUserData(payload),
      };
    },
    [UPDATE_USER]: (state, { payload }) => ({
      ...state,
      ...handleUserData(payload),
    }),
    [LOGOUT]: () => {
      window.sessionStorage.removeItem(TOKEN);
      window.sessionStorage.removeItem(TOKEN_2FA);

      return getInit();
    },
    [UPDATE_2FA_LINK]: (state, { payload }) => ({
      ...state,
      [LINK_2FA]: prop('url', payload),
    }),
    [UPDATE_USER_DATA]: (state, { payload }) => ({
      ...state,
      [USER]: payload,
    }),
  },
  getInit()
);
