import React, { useState, useMemo, useCallback, useEffect, Fragment } from 'react';
import { times } from 'ramda';
import PropTypes from 'prop-types';

import { usePlanningActions, useAlerts, ID, DATE, DAYS, SHIFTS } from 'store/planning';
import Section from 'components/QuestionContainer';
import Download from 'assets/svg-icons/Download';
import Spinner from 'components/FullScreenSpinner';
import { DAYS_OPTIONS, TIME_OPTIONS } from '../../consts';
import DateInput from '../../DateInput';

import { validateFrom, getHoursAndMinutes, resetShifts } from './utils';
import { useFormData } from './hooks';
import { Container, Wrapper, Item, Label, Select, SmallText, Text, Status, Button, IconWrap, Message } from './styles';

const Form = ({ readMode, disabled, errors, setErrors, setDateError }) => {
  const { setScheduleUpdates, exportSchedule, getScheduleLabels } = usePlanningActions();
  const { action: getSchedule, loading: scheduleLoading, error: scheduleErr } = useAlerts(exportSchedule);
  const { action: getLabels, loading: labelsLoading, error: labelsErr } = useAlerts(getScheduleLabels);

  const { id, values, status, editor } = useFormData();
  const [index, setIndex] = useState(0);
  const date = values[DATE];
  const days = values[DAYS];
  const shifts = values[SHIFTS];
  const indexes = useMemo(() => times((i) => ({ id: i, label: `${i + 1}. Tag` }), shifts.length), [shifts.length]);
  const [time1, time2] = useMemo(() => (shifts[index] ? shifts[index].map(getHoursAndMinutes) : []), [index, shifts]);

  const updateDate = useCallback(
    (val) => setScheduleUpdates({ [ID]: id, [DATE]: val, [SHIFTS]: resetShifts(val, shifts) }),
    [id, setScheduleUpdates, shifts]
  );
  const updateDays = useCallback(
    (val) => {
      const value = Number(val);

      setIndex(($) => (value - 1 < Number($) ? String(value - 1) : $));
      setScheduleUpdates({ [ID]: id, [DAYS]: value, [SHIFTS]: resetShifts(date, shifts, value) });
    },
    [id, setScheduleUpdates, shifts, date]
  );
  const updateDayDate = useCallback(
    (val) => {
      const items = [...shifts];
      items[index] = shifts[index].map((v) => {
        const current = new Date(val);
        const d = new Date(v);

        current.setHours(d.getHours());
        current.setMinutes(d.getMinutes());

        return current.toISOString();
      });

      setScheduleUpdates({ [ID]: id, [SHIFTS]: items });
    },
    [id, index, setScheduleUpdates, shifts]
  );
  const updateTimeStart = useCallback(
    (time) => {
      const items = [...shifts];
      const [h, m] = time.split(':').map(Number);
      const d = new Date(items[index][0]);

      d.setHours(h);
      d.setMinutes(m);
      items[index][0] = d.toISOString();

      setScheduleUpdates({ [ID]: id, [SHIFTS]: items });
    },
    [id, index, setScheduleUpdates, shifts]
  );
  const updateTimeEnd = useCallback(
    (time) => {
      const items = [...shifts];
      const [h, m] = time.split(':').map(Number);
      const d = new Date(items[index][1]);

      d.setHours(h);
      d.setMinutes(m);
      items[index][1] = d.toISOString();

      setScheduleUpdates({ [ID]: id, [SHIFTS]: items });
    },
    [id, index, setScheduleUpdates, shifts]
  );
  const onDownloadSchedule = useCallback(() => getSchedule(id), [getSchedule, id]);
  const onDownloadLabels = useCallback(() => getLabels(id), [getLabels, id]);

  useEffect(() => {
    validateFrom(values, setErrors);
  }, [setErrors, values]);

  return (
    <Section label="Übersicht">
      <Container>
        <Wrapper>
          <Item>
            <Label>Startdatum</Label>
            <DateInput
              value={date}
              onChange={updateDate}
              setValidationError={setDateError}
              readMode={readMode}
              disabled={disabled}
            />
            {!(readMode || disabled) && errors[DATE] && <Message>{errors[DATE]}</Message>}
          </Item>
          <Item>
            <Label>Messtage</Label>
            <Select value={days} options={DAYS_OPTIONS} onSelect={updateDays} disabled={disabled} readMode={readMode} />
            {!(readMode || disabled) && errors[DAYS] && <Message>{errors[DAYS]}</Message>}
          </Item>
          <Item>
            <div />
            <Select value={index} options={indexes} onSelect={setIndex} disabled={disabled} readMode={readMode} />
          </Item>
          {shifts[index] && (
            <Fragment key={`${index}-${date}-${days}`}>
              <Item>
                <Label>Datum</Label>
                <DateInput
                  value={shifts[index][0]}
                  onChange={updateDayDate}
                  setValidationError={setDateError}
                  readMode={readMode}
                  disabled={disabled}
                />
              </Item>
              <Item>
                <Label>Uhrzeit</Label>
                <Select value={time1} options={TIME_OPTIONS} onSelect={updateTimeStart} disabled={disabled} readMode={readMode} />
                <SmallText>bis</SmallText>
                <Select value={time2} options={TIME_OPTIONS} onSelect={updateTimeEnd} disabled={disabled} readMode={readMode} />
              </Item>
            </Fragment>
          )}
        </Wrapper>
        <Wrapper>
          <Item>
            <Label>Zul. bearbeitet</Label>
            <Text>{editor}</Text>
          </Item>
          <Item>
            <Label>Status</Label>
            <Status $color={status.color}>{status.label}</Status>
          </Item>
          <Item>
            <Label>Terminplan als Excel-Datei</Label>
            <Button onClick={onDownloadSchedule} disabled={scheduleLoading} title="Download XLS">
              {scheduleLoading ? (
                <Spinner size={46} height="100%" />
              ) : (
                <IconWrap $error={scheduleErr}>
                  <Download />
                </IconWrap>
              )}
            </Button>
          </Item>
          <Item>
            <Label>Patienten-Etiketten (PDF)</Label>
            <Button onClick={onDownloadLabels} disabled={labelsLoading} title="Download XLS">
              {labelsLoading ? (
                <Spinner size={46} height="100%" />
              ) : (
                <IconWrap $error={labelsErr}>
                  <Download />
                </IconWrap>
              )}
            </Button>
          </Item>
        </Wrapper>
      </Container>
    </Section>
  );
};

Form.defaultProps = { disabled: false };
Form.propTypes = {
  readMode: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  setErrors: PropTypes.func.isRequired,
  setDateError: PropTypes.func.isRequired,
};

export default Form;
