import React, { memo, useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { prop, T, path } from 'ramda';
import PropTypes from 'prop-types';

import {
  KIND,
  CONTACT_KINDS,
  CONTACT_FORM_NAME,
  CONTACT_TITLE,
  ORGANIZATION_KINDS,
  SUB_OPTICIAN,
  CONTACTS,
  useOrganizationActions,
  useAlerts,
} from 'store/organizations';
import Accordion from 'components/Accordion';

import MainForm from './MainForm';
import OpticianForm from './OpticianForm';
import DoctorForm from './DoctorForm';
import ReportsForm from './ReportsForm';
import { getInitValues, extractOpticianId } from './utils';
import ImportExamination from './ImportExamination';

const OrganizationForm = ({ organization }) => {
  const { updateOrganization } = useOrganizationActions();
  const { extractId, errors } = useAlerts();
  const kind = useMemo(() => prop(KIND, organization), [organization]);

  const isNurseHome = useMemo(() => kind === ORGANIZATION_KINDS.NURSING_HOME, [kind]);
  const isClinic = useMemo(() => kind === ORGANIZATION_KINDS.CLINIC, [kind]);
  const initialValues = useMemo(() => getInitValues(organization), [organization]);

  const sendData = useCallback(
    (values) => {
      extractId(updateOrganization({ ...values, [SUB_OPTICIAN]: extractOpticianId(values) }));
    },
    [extractId, updateOrganization]
  );

  return (
    <Formik initialValues={initialValues} validate={sendData} validateOnBlur={false}>
      {({ getFieldProps }) => (
        <>
          <Accordion label={CONTACT_TITLE[kind]} defaultOpen>
            <MainForm getFieldProps={getFieldProps} isClinic={isClinic} errors={errors} />
          </Accordion>
          {isNurseHome && (
            <Accordion label="Optiker">
              <OpticianForm />
            </Accordion>
          )}
          {CONTACT_KINDS[kind].map((type) => (
            <Accordion key={type} label={CONTACT_FORM_NAME[type]}>
              <DoctorForm
                getFieldProps={getFieldProps}
                type={type}
                isClinic={isClinic}
                errors={path([CONTACTS, type], errors || null)}
              />
            </Accordion>
          ))}
          {isNurseHome && (
            <>
              <Accordion label="Berichte">
                <ReportsForm />
              </Accordion>
              <ImportExamination organization={organization} />
            </>
          )}
        </>
      )}
    </Formik>
  );
};

OrganizationForm.propTypes = {
  organization: PropTypes.shape({}).isRequired,
};

export default memo(OrganizationForm, T);
