import { path, pathOr } from 'ramda';

import { FEATURE_NAME as $, IMPORTS, FILTERS, CURRENT } from './consts';

export const getCurrentOrganization = path([$, CURRENT]);
export const getCurrentOrganizationProp = (name) => path([$, CURRENT, name]);
export const getOrganizations = (kind) => path([$, kind]);
export const getOrganization = (kind, id) => path([$, kind, id]);
export const getOrganizationsFilters = (kind) => path([$, FILTERS, kind]);
export const getImports = (type) => pathOr([], [$, IMPORTS, type]);
