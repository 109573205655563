export const FEATURE_NAME = 'organizations';

export const ORGANIZATION_KINDS = {
  AUGENMOBIL: 1,
  NURSING_HOME: 2,
  OPTICIAN: 3,
  CLINIC: 4,
};

export const IMPORTS = 'importsLists';
export const CURRENT = 'current';

export const KIND = 'kind';

export const NAME = 'name';
export const ADDRESS = 'address';
export const CITY = 'city';
export const ZIP_CODE = 'zipCode';
export const CANTON = 'canton';
export const COUNTRY = 'country';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const ORG_URL = 'url';
export const TITLE = 'title';
export const GENDER = 'gender';
export const SPECIALTY = 'specialty';
export const DESCRIPTION = 'description';
export const CHANNEL = 'channel';
export const SHARE = 'share';

export const CONTACTS = 'contacts';
export const DOCTOR = 'gp';
export const MAIN = 'main';
export const OPHTHALMOLOGY = 'ophthalmology';

export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const NO_PATIENT_REPORTS = 'noPatientReports';

export const FILTERS = 'filters';
export const SUCCESS_IMPORTS = 'successImports';
export const ERROR_IMPORTS = 'errorImports';

export const CONTACT_FORM_NAME = {
  [MAIN]: 'Kontaktperson',
  [DOCTOR]: 'Heimarzt/-ärztin',
};

export const CONTACT_TITLE = {
  [ORGANIZATION_KINDS.CLINIC]: 'Praxis/Klinik',
  [ORGANIZATION_KINDS.NURSING_HOME]: 'Pflegezentrum',
  [ORGANIZATION_KINDS.OPTICIAN]: 'Optiker',
};

export const CONTACT_KINDS = {
  [ORGANIZATION_KINDS.CLINIC]: [MAIN],
  [ORGANIZATION_KINDS.NURSING_HOME]: [MAIN, DOCTOR],
  [ORGANIZATION_KINDS.OPTICIAN]: [MAIN],
};

export const ORGANIZATION_STATUS = [
  { id: 1, label: 'Aktiv' },
  { id: 0, label: 'Inaktiv' },
];

export const SERVICES = 'services';
export const SERVICE_ITEMS = [
  {
    id: 'yagLaser',
    label: 'YAG-Laser',
  },
  {
    id: 'wheelchairAccessible',
    label: 'Praxis rollstuhlgängig',
  },
  {
    id: 'smallLiftOnly',
    label: 'nur kleiner Lift',
  },
];
