export const FEATURE_NAME = 'patients';

export const LIST = 'list';
export const FILTERS = 'filters';

export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const GENDER = 'gender';
export const BIRTH = 'birthday';
export const ADDRESS = 'address';
export const CARE_OF = 'careOf';
export const ZIP_CODE = 'zipCode';
export const CANTON = 'canton';
export const COUNTRY = 'country';
export const CITY = 'city';
export const TOWN = 'town';
export const STREET = 'street';
export const HOUSE = 'houseNo';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const NATIONAL = 'nationality';
// export const NUMBER = 'number';
// export const VEKA = 'veka';
export const MODEL = 'model';
export const SUPPLEMENT = 'supplement';
export const SSN = 'ssn';
export const REPORTER = 'correspondence';
export const WARD = 'ward';
export const TITLE = 'title';
export const NURSING_HOME = 'nursingHome';
export const COPY_FROM = 'copyFrom';
export const INSTITUTION = 'institution';
export const INSURANCE = 'insurance';
export const NAME = 'name';
export const PORTRAIT = 'portrait';
export const REMUNERATION = 'remuneration';
export const TIERS_GARANT = 'tiers_garant';
export const TIERS_PAYANT = 'tiers_payant';
export const FROM = 'from';
export const TO = 'to';

export const CONTACTS = 'contacts';

export const AGENT = 'representative';
export const EMERGENCY = 'emergency';
export const FAMILY_DOCTOR = 'gp';
export const EYE_DOCTOR = 'eyeDoctor';

export const OPTICIAN = 'optician';
export const GUARANTY = 'costGuaranty';
export const PATIENT = 'patient';

export const PUBLIC_ID = 'publicId';

export const PATIENT_REPORTS = 'patient_reports';
export const REPORTS = 'reports';
export const NO_REPORT = 'noReport';

export const LAW_TYPE = 'lawType';
export const LAW_TYPES = 'lawTypes';
export const LAW_TYPE_KINDS = { KVG: 1, UVG: 2, IVG: 3, MVG: 4 };
export const LAW_TYPE_LABELS = {
  [LAW_TYPE_KINDS.KVG]: 'KVG',
  [LAW_TYPE_KINDS.UVG]: 'UVG',
  [LAW_TYPE_KINDS.IVG]: 'IVG',
  [LAW_TYPE_KINDS.MVG]: 'MVG',
};
