import moment from 'moment';
import { path, pathOr, propOr } from 'ramda';

import {
  BIRTH,
  INSURANCE,
  CONTACTS,
  NURSING_HOME,
  OPTICIAN,
  FAMILY_DOCTOR,
  EYE_DOCTOR,
  AGENT,
  EMERGENCY,
  SUB_ORGANIZATION,
  ID,
} from 'store/patients';
import { parseUTCDate } from 'utils';

import { defaultValues } from './consts';

const listMisses = [CONTACTS, INSURANCE, NURSING_HOME, OPTICIAN, FAMILY_DOCTOR, EYE_DOCTOR];

export const toExtract = (def, current) =>
  Object.entries(def).reduce((acc, [key, value]) => {
    if (key === BIRTH && current[key] && moment(new Date(current[key])).isValid()) {
      acc[key] = parseUTCDate(current[key]);
      return acc;
    }

    if (listMisses.includes(key)) {
      return acc;
    }

    if (typeof current[key] === 'boolean') {
      acc[key] = current[key];
      return acc;
    }

    acc[key] = current[key] || value;
    return acc;
  }, {});

export const getInitValues = (data = {}) => ({
  ...toExtract(defaultValues, data),
  [NURSING_HOME]: {
    ...toExtract(defaultValues[NURSING_HOME], data[NURSING_HOME] || {}),
  },
  [OPTICIAN]: {
    ...toExtract(defaultValues[OPTICIAN], data[OPTICIAN] || {}),
  },
  [CONTACTS]: {
    [AGENT]: {
      ...toExtract(path([CONTACTS, AGENT], defaultValues), path([CONTACTS, AGENT], data) || {}),
    },
    [EMERGENCY]: {
      ...toExtract(path([CONTACTS, EMERGENCY], defaultValues), path([CONTACTS, EMERGENCY], data) || {}),
    },
  },
  [INSURANCE]: {
    ...toExtract(defaultValues[INSURANCE], data[INSURANCE] || {}),
  },
  [FAMILY_DOCTOR]: {
    ...toExtract(defaultValues[FAMILY_DOCTOR], data[FAMILY_DOCTOR] || {}),
  },
  [EYE_DOCTOR]: {
    ...toExtract(defaultValues[EYE_DOCTOR], data[EYE_DOCTOR] || {}),
  },
});

export const extractOrgData = (fields, data = {}) => {
  const id = pathOr(propOr(null, SUB_ORGANIZATION, data), [SUB_ORGANIZATION, ID], data);

  return id
    ? { ...data, [SUB_ORGANIZATION]: id }
    : {
        ...data,
        [SUB_ORGANIZATION]: null,
        ...fields.reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
      };
};
