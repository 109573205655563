import React from 'react';
import { useField } from 'formik';

import { NO_PATIENT_REPORTS } from 'store/organizations';
import Checkbox from 'components/CheckboxNew';

const ReportsForm = () => {
  const [{ value }, , { setValue }] = useField(NO_PATIENT_REPORTS);

  return <Checkbox label="Keine Berichte an Patienten senden" selected={value} onClick={setValue} />;
};

export default ReportsForm;
