import { useMemo } from 'react';
import { allPass, prop, complement, T, propOr } from 'ramda';

import {
  PATIENT,
  CONTACTS,
  NURSING_HOME,
  OPTICIAN,
  AGENT,
  EMERGENCY,
  FAMILY_DOCTOR,
  EYE_DOCTOR,
  REPORTER,
  COPY_FROM,
  usePatient,
} from 'store/patients';

import { contactNames } from './consts';

const checkContact = allPass([prop(REPORTER), complement(prop(COPY_FROM))]);

export const useContactsForPatient = (patientId) => {
  const [contacts, nursingHome, optician, gp, eyeDoctor] = usePatient(
    patientId,
    [CONTACTS, NURSING_HOME, OPTICIAN, FAMILY_DOCTOR, EYE_DOCTOR],
    T
  );

  return useMemo(
    () =>
      contactNames.filter(({ id }) => {
        if (id === PATIENT) return true;
        if (id === NURSING_HOME) return checkContact(nursingHome);
        if (id === OPTICIAN) return checkContact(optician);
        if (id === AGENT) return checkContact(propOr({}, AGENT, contacts));
        if (id === EMERGENCY) return checkContact(propOr({}, EMERGENCY, contacts));
        if (id === FAMILY_DOCTOR) return checkContact(gp);
        if (id === EYE_DOCTOR) return checkContact(eyeDoctor);
        return false;
      }),
    [contacts, nursingHome, optician, gp, eyeDoctor]
  );
};
