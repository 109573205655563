import React from 'react';
import PropTypes from 'prop-types';

import { Container, Label } from './styles';

const FormQuestion = ({ inlineStyle, alignedToTop, question, children }) => (
  <Container inlineStyle={inlineStyle}>
    <Label smallLabel={inlineStyle} alignedToTop={alignedToTop} withBottomMargin={!inlineStyle}>
      {question}
    </Label>
    {children}
  </Container>
);

FormQuestion.defaultProps = {
  inlineStyle: false,
  alignedToTop: false,
  question: '',
};

FormQuestion.propTypes = {
  inlineStyle: PropTypes.bool,
  alignedToTop: PropTypes.bool,
  question: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FormQuestion;
