import { useMemo } from 'react';
import { pipe, propOr, props, filter, join } from 'ramda';

import { useCurrentSchedule, useScheduleUpdates, ID, DATE, DAYS, SHIFTS, EDITOR, STATUS, META } from 'store/planning';
import { TITLE, FIRST_NAME, LAST_NAME } from 'store/session';
import { POSITION_MAP } from 'utils/constants';
import { STATUSES } from '../../consts';

export const useFormData = () => {
  const [id, date, shifts, meta, status] = useCurrentSchedule([ID, DATE, SHIFTS, META, STATUS]);
  const [updatedDate, days, updatedShifts] = useScheduleUpdates(id, [DATE, DAYS, SHIFTS]);
  const values = useMemo(
    () => ({
      [DATE]: updatedDate || date,
      [DAYS]: days || shifts?.length || '',
      [SHIFTS]: updatedShifts || shifts || [],
    }),
    [date, days, shifts, updatedDate, updatedShifts]
  );

  return {
    id,
    values,
    status: useMemo(() => STATUSES[status] || {}, [status]),
    editor: useMemo(
      () =>
        pipe(
          propOr({}, EDITOR),
          ($) => [POSITION_MAP[$[TITLE]], ...props([FIRST_NAME, LAST_NAME], $)],
          filter(Boolean),
          join(' ')
        )(meta),
      [meta]
    ),
  };
};
