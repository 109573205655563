import moment from 'moment';
import { map, pipe, prop, applySpec, props, join, filter } from 'ramda';

import { FIRST_NAME, LAST_NAME, BIRTH, PUBLIC_ID } from 'store/patients';
import { parseBirthday } from 'utils';

export const tableHead = [
  {
    id: PUBLIC_ID,
    content: 'Patienten-ID',
    width: '15%',
  },
  {
    id: LAST_NAME,
    content: 'Name',
    width: '50%',
  },
  {
    id: BIRTH,
    content: 'Geburtstag',
    width: '15%',
  },
  {
    id: 'examinationDate',
    content: 'Untersuchungsdatum',
    width: '20%',
  },
];

export const handleImports = map(
  applySpec({
    id: pipe(props([PUBLIC_ID, 'examinationDate']), join('.')),
    publicId: prop(PUBLIC_ID),
    name: pipe(props([LAST_NAME, FIRST_NAME]), filter(Boolean), join(', ')),
    birth: pipe(prop(BIRTH), parseBirthday),
    date: ({ examinationDate }) => examinationDate && moment(examinationDate).format('DD.MM.YYYY, HH.mm'),
  })
);
