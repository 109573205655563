import { STATUS, DATE, REGISTRATIONS, SUB_ORGANIZATION, NAME, META, EDITOR, ALL, DAYS } from 'store/planning';

export const STATUS_OPTIONS = [
  { id: ALL, title: 'Alle' },
  { id: '0', title: 'In Vorbereitung' },
  { id: '1', title: 'Geplant' },
  { id: '2', title: 'Gestartet' },
  { id: '3', title: 'Abgeschlossen' },
];

export const TABLE_HEAD = [
  { id: DATE, content: 'Datum', width: '12%' },
  { id: DAYS, content: 'Messtage', width: '9%', withoutSort: true },
  { id: REGISTRATIONS, content: 'Anmeldungen', width: '15%', withoutSort: true },
  { id: `${SUB_ORGANIZATION}.${NAME}`, content: 'Institution', width: '31%', withoutSort: true },
  { id: `${META}.${EDITOR}`, content: 'Zul. bearbeitet', width: '14%', withoutSort: true },
  { id: STATUS, content: 'Status', width: '12%' },
  { id: 'excel', content: 'Excel', width: '7%', withoutSort: true },
];
