import React, { useState, useMemo, useCallback, Fragment } from 'react';
import { times } from 'ramda';
import PropTypes from 'prop-types';

import { ID, NAME, SUB_ORGANIZATION, ORGANIZATION_KINDS, useCollectOrganizationsAction } from 'store/organizations';
import { DATE, DAYS, SHIFTS } from 'store/planning';
import { DAYS_OPTIONS, TIME_OPTIONS } from '../../consts';
import DateInput from '../../DateInput';

import { getHoursAndMinutes, resetShifts } from './utils';
import { Container, Label, InputWrap, Dropdown, Select, Wrapper, Text } from './styles';

const Form = ({ values, setFieldValue, errors, setDateError, disabled }) => {
  const getOrganizations = useCollectOrganizationsAction(ORGANIZATION_KINDS.NURSING_HOME);
  const date = values[DATE];
  const days = values[DAYS];
  const shifts = values[SHIFTS];
  const [index, setIndex] = useState(0);
  const indexes = useMemo(() => times((i) => ({ id: i, label: `${i + 1}. Tag` }), shifts.length), [shifts]);
  const [time1, time2] = useMemo(() => shifts[index].map(getHoursAndMinutes), [index, shifts]);

  const updateOrganization = useCallback((org) => setFieldValue(SUB_ORGANIZATION, org), [setFieldValue]);
  const updateDate = useCallback(
    (value) => {
      setFieldValue(DATE, value);
      setFieldValue(SHIFTS, resetShifts(value, shifts));
    },
    [setFieldValue, shifts]
  );
  const updateDays = useCallback(
    (val) => {
      const value = Number(val);

      setFieldValue(DAYS, value);
      setFieldValue(SHIFTS, resetShifts(date, shifts, value));
    },
    [date, setFieldValue, shifts]
  );
  const updateDayDate = useCallback(
    (val) => {
      const items = [...shifts];
      items[index] = shifts[index].map((v) => {
        const current = new Date(val);
        const d = new Date(v);

        current.setHours(d.getHours());
        current.setMinutes(d.getMinutes());

        return current.toISOString();
      });

      setFieldValue(SHIFTS, items);
    },
    [index, setFieldValue, shifts]
  );
  const updateTimeStart = useCallback(
    (time) => {
      const items = [...shifts];
      const [h, m] = time.split(':').map(Number);
      const d = new Date(items[index][0]);

      d.setHours(h);
      d.setMinutes(m);
      items[index][0] = d.toISOString();

      setFieldValue(SHIFTS, items);
    },
    [index, setFieldValue, shifts]
  );
  const updateTimeEnd = useCallback(
    (time) => {
      const items = [...shifts];
      const [h, m] = time.split(':').map(Number);
      const d = new Date(items[index][1]);

      d.setHours(h);
      d.setMinutes(m);
      items[index][1] = d.toISOString();

      setFieldValue(SHIFTS, items);
    },
    [index, setFieldValue, shifts]
  );

  return (
    <Container>
      <Label>Pflegeheim</Label>
      <InputWrap $error={errors[SUB_ORGANIZATION]} $fullWidth>
        <Dropdown
          idKey={ID}
          nameKey={NAME}
          action={getOrganizations}
          placeholder="Pflegeheim suchen"
          value={values[SUB_ORGANIZATION]}
          onChange={updateOrganization}
          disabled={disabled}
          onlyOne
        />
      </InputWrap>
      <Label>Startdatum</Label>
      <InputWrap $error={errors[DATE]}>
        <DateInput value={date} onChange={updateDate} setValidationError={setDateError} disabled={disabled} />
      </InputWrap>
      <Label $isSmall>Messtage</Label>
      <InputWrap $error={errors[DAYS]}>
        <Select value={days || ''} options={DAYS_OPTIONS} onSelect={updateDays} disabled={disabled} />
      </InputWrap>
      <div />
      <InputWrap>
        <Select value={index} options={indexes} onSelect={setIndex} disabled={disabled} />
      </InputWrap>
      {shifts[index] && (
        <Fragment key={`${index}-${date}-${days}`}>
          <Label>Datum</Label>
          <InputWrap>
            <DateInput value={shifts[index][0]} onChange={updateDayDate} setValidationError={setDateError} disabled={disabled} />
          </InputWrap>
          <Label>Uhrzeit</Label>
          <InputWrap>
            <Wrapper>
              <Select value={time1} options={TIME_OPTIONS} onSelect={updateTimeStart} disabled={disabled} />
              <Text>bis</Text>
              <Select value={time2} options={TIME_OPTIONS} onSelect={updateTimeEnd} disabled={disabled} />
            </Wrapper>
          </InputWrap>
        </Fragment>
      )}
    </Container>
  );
};

Form.defaultProps = { disabled: false };
Form.propTypes = {
  values: PropTypes.shape({
    [SUB_ORGANIZATION]: PropTypes.shape({
      [ID]: PropTypes.string.isRequired,
    }),
    [DATE]: PropTypes.string.isRequired,
    [DAYS]: PropTypes.number.isRequired,
    [SHIFTS]: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired).isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  setDateError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Form;
