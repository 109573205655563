import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { prop, propOr, pathOr } from 'ramda';
import PropTypes from 'prop-types';

import { ID, NAME, ORGANIZATION_KINDS, SUB_OPTICIAN, useCollectOrganizationsAction, useOrganization } from 'store/organizations';
import { OPTICIAN, NURSING_HOME, SUB_ORGANIZATION, REPORTER, GUARANTY } from 'store/patients';
import Checkbox from 'components/CheckboxNew';
import PaginationDropDown from 'components/PaginationDropDown';
import CreateComponent from '../CreateOrganizationItem';
import { WarnMessage } from '../styles';

import { NAME_KEY, PARAMS, handleClinicName } from './utils';
import Item from './Item';

const Optician = ({ readMode }) => {
  const getOrganizations = useCollectOrganizationsAction(ORGANIZATION_KINDS.OPTICIAN, PARAMS);

  const [{ value: optician }, , { setValue: setOptician }] = useField([OPTICIAN, SUB_ORGANIZATION].join('.'));
  const [{ value: nursingHome }] = useField([NURSING_HOME, SUB_ORGANIZATION].join('.'));
  const [{ value: reporter }, , { setValue: setReporter }] = useField([OPTICIAN, REPORTER].join('.'));
  const [{ value: guaranty }, , { setValue: setGuaranty }] = useField([OPTICIAN, GUARANTY].join('.'));

  const [opticianId, defaultOpticianId] = useMemo(
    () => [prop(ID, optician || {}), pathOr(prop(SUB_OPTICIAN, nursingHome || {}), [SUB_OPTICIAN, ID], nursingHome || {})],
    [nursingHome, optician]
  );

  const { organization: defaultOptician } = useOrganization(defaultOpticianId);

  const warnMessage = useMemo(() => {
    const nursingHomeName = propOr('–', NAME, nursingHome || {});
    const defaultOpticianName = propOr('–', NAME, defaultOptician || {});
    if (!(defaultOpticianId && defaultOptician && opticianId) || defaultOpticianId === opticianId) return null;

    return `Hinweis: ${defaultOpticianName} ist der Standard-Optiker im Pflegezentrum ${nursingHomeName}.`;
  }, [defaultOptician, defaultOpticianId, nursingHome, opticianId]);

  const handleChangeGuaranty = useCallback(() => {
    if (!readMode && optician) setGuaranty(!guaranty);
  }, [guaranty, optician, readMode, setGuaranty]);

  const handleChangeReporter = useCallback(() => {
    if (!readMode && optician) setReporter(!reporter);
  }, [optician, readMode, reporter, setReporter]);

  const onChange = useCallback(
    (value) => {
      setOptician(value);

      if (!value) {
        setReporter(false, false);
        setGuaranty(false, false);
      }
    },
    [setGuaranty, setOptician, setReporter]
  );

  const latestItem = useMemo(() => <CreateComponent kind={ORGANIZATION_KINDS.OPTICIAN} />, []);

  return (
    <>
      <Checkbox
        label="Patient wünscht Kostengutsprache"
        selected={guaranty}
        onClick={handleChangeGuaranty}
        readMode={readMode || !optician}
      />
      <Checkbox
        label="Erhält Korrespondenz"
        selected={reporter}
        onClick={handleChangeReporter}
        readMode={readMode || !optician}
      />
      <PaginationDropDown
        idKey={ID}
        nameKey={NAME_KEY}
        disabledId={defaultOpticianId}
        action={getOrganizations}
        placeholder="Optiker suchen"
        value={optician}
        onChange={onChange}
        applyFilter={handleClinicName}
        selectedComponent={Item}
        latestItem={latestItem}
        disabled={readMode}
        onlyOne
      />
      {warnMessage && <WarnMessage type="error">{warnMessage}</WarnMessage>}
    </>
  );
};

Optician.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default Optician;
