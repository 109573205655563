import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid var(--color-grey6A);
  margin: 50px 0;
`;

export const Label = styled.div`
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
  line-height: 1.2;
  letter-spacing: 0;
  text-align: left;
  margin: 5px 0 10px 0;
`;
