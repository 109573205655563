import { mergeDeepLeft, pipe, prop, __, pathOr, propOr } from 'ramda';

import {
  ID,
  NAME,
  ADDRESS,
  CITY,
  CANTON,
  COUNTRY,
  ZIP_CODE,
  PHONE,
  ORG_URL,
  SUB_OPTICIAN,
  CONTACTS,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  NO_PATIENT_REPORTS,
  GENDER,
  EMAIL,
  KIND,
  SPECIALTY,
  CONTACT_KINDS,
  SERVICES,
  DESCRIPTION,
  STATUS,
} from 'store/organizations';

const getDefaultValues = (contactNames) => ({
  [ID]: '',
  [NAME]: '',
  [ADDRESS]: '',
  [CITY]: '',
  [ZIP_CODE]: '',
  [CANTON]: '',
  [COUNTRY]: 'CH',
  [PHONE]: '',
  [ORG_URL]: '',
  [SUB_OPTICIAN]: null,
  [SPECIALTY]: '',
  [SERVICES]: {},
  [NO_PATIENT_REPORTS]: false,
  [DESCRIPTION]: '',
  [STATUS]: 0,
  [CONTACTS]: contactNames.reduce((acc, contactName) => {
    acc[contactName] = {
      [TITLE]: '',
      [FIRST_NAME]: '',
      [LAST_NAME]: '',
      [GENDER]: '',
      [EMAIL]: '',
      [PHONE]: '',
    };
    return acc;
  }, {}),
});

export const getInitValues = (organization) =>
  pipe(prop(KIND), prop(__, CONTACT_KINDS), getDefaultValues, mergeDeepLeft(organization))(organization);

export const extractOpticianId = (data = {}) => pathOr(propOr(null, SUB_OPTICIAN, data), [SUB_OPTICIAN, ID], data);
