import React, { useCallback } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { PROCEDURE, COMMENT } from 'store/diagnoses';
import dictionary from 'utils/dictionary.json';
import TextArea from 'components/TextAreaNew';

import Button from './Button';
import { Container } from './styles';

const TextAreaWithClues = ({ readMode }) => {
  const [{ value }, , { setValue }] = useField(`${PROCEDURE}.${COMMENT}`);
  const handleChange = useCallback(
    (text) => {
      if (readMode) return;

      setValue(text);
    },
    [readMode, setValue]
  );

  return (
    <>
      {readMode || (
        <Container>
          {dictionary.recommendationClues.map(({ id, title, text }) => (
            <Button
              key={id}
              title={title}
              text={text}
              value={value}
              setValue={setValue}
              width={100 / dictionary.recommendationClues.length}
            />
          ))}
        </Container>
      )}
      <TextArea value={value} onChangeText={handleChange} readMode={readMode} />
    </>
  );
};

TextAreaWithClues.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default TextAreaWithClues;
