import React, { useRef, useCallback, useEffect } from 'react';
import { pipe, props, filter, join } from 'ramda';
import PropTypes from 'prop-types';

import { usePatientsActions, useAlerts, ADDRESS, CANTON, CITY, ZIP_CODE, STREET, HOUSE, COUNTRY } from 'store/patients';
import { useAsyncState } from 'utils/useAsyncState';
import countries from 'assets/countries.json';
import TextInput from 'components/TextInputNew';
import Select from 'components/SelectNew';

import List from './List';

const AddressAutocomplete = ({ address, zipCode, city, canton, country, setData, zipCodeErr, readMode }) => {
  const ref = useRef();
  const timer = useRef();
  const [list, setList] = useAsyncState([]);
  const [focused, setFocused] = useAsyncState(null);
  const { autocompleteAddress } = usePatientsActions();
  const { action, loading, success, resetAlerts } = useAlerts(autocompleteAddress);
  const setCanton = useCallback((v) => setData({ [CANTON]: v }), [setData]);
  const setCountry = useCallback((v) => setData({ [COUNTRY]: v }), [setData]);

  const updateAddress = useCallback(
    (data) => {
      setData({ [ADDRESS]: data });

      if (country !== 'CH') return;

      const [house] = data.trim().match(/\d+\w*$/i) || [];
      const street = (house ? data.replace(house, '') : data).replace(/\W+/gi, '');

      if (street) action({ [ZIP_CODE]: zipCode.length === 4 ? zipCode : '', [STREET]: street, [HOUSE]: house || '' });
    },
    [action, country, setData, zipCode]
  );
  const updateZipCode = useCallback(
    (data) => {
      setData({ [ZIP_CODE]: data });

      if (country === 'CH' && data.length === 4) action({ [ZIP_CODE]: data });
    },
    [action, country, setData]
  );
  const updateCity = useCallback(
    (data) => {
      setData({ [CITY]: data });

      if (country === 'CH' && data) action({ [CITY]: data });
    },
    [action, country, setData]
  );

  const isNoZipCode = !zipCode;
  const onSelect = useCallback(
    (data) => {
      setData(
        focused === ADDRESS
          ? {
              ...(Boolean(data[STREET]) && { [ADDRESS]: [data[STREET], data[HOUSE]].filter(Boolean).join(' ') }),
              ...(isNoZipCode && Boolean(data[ZIP_CODE]) && { [ZIP_CODE]: data[ZIP_CODE] }),
              ...(isNoZipCode && Boolean(data[CANTON]) && { [CANTON]: data[CANTON] }),
              ...(isNoZipCode && Boolean(data[CITY]) && { [CITY]: data[CITY] }),
            }
          : {
              ...(Boolean(data[ZIP_CODE]) && { [ZIP_CODE]: data[ZIP_CODE] }),
              ...(Boolean(data[CANTON]) && { [CANTON]: data[CANTON] }),
              ...(Boolean(data[CITY]) && { [CITY]: data[CITY] }),
            }
      );
      setList([]);
      resetAlerts();
    },
    [focused, isNoZipCode, resetAlerts, setData, setList]
  );

  const onFocus = useCallback(
    ({ currentTarget }) => {
      setList([]);
      resetAlerts();
      clearTimeout(timer.current);
      setFocused(currentTarget.dataset?.id || null);
    },
    [setFocused, setList, resetAlerts]
  );
  const onBlur = useCallback(() => {
    timer.current = setTimeout(() => setFocused(null), 150);
  }, [setFocused]);
  const isZipCodeFocused = focused === ZIP_CODE;

  useEffect(() => {
    if (!success) return;
    if (success.length === 1 && isZipCodeFocused) {
      onSelect(success[0]);
    } else {
      setList(
        success.map((item, i) => ({
          id: `${pipe(props([ZIP_CODE, CANTON, CITY, STREET, HOUSE]), filter(Boolean), join(' '))(item || {})}-${i}`,
          ...item,
        }))
      );
    }
    if (success.length > 1) ref.current?.scrollTo(0, 0);
  }, [isZipCodeFocused, onSelect, setList, success]);

  useEffect(() => () => clearTimeout(timer.current), []);

  return (
    <>
      <TextInput
        label="Adresse"
        value={address}
        handleChangeText={updateAddress}
        data-id={ADDRESS}
        onFocus={onFocus}
        onBlur={onBlur}
        readMode={readMode}
      />
      {!readMode && focused === ADDRESS && list.length > 0 && (
        <List ref={ref} items={list} hasZipCode={zipCode.length === 4} onSelect={onSelect} loading={loading} />
      )}
      <TextInput
        label="PLZ"
        value={zipCode}
        handleChangeText={updateZipCode}
        data-id={ZIP_CODE}
        onFocus={onFocus}
        onBlur={onBlur}
        readMode={readMode}
        error={!readMode && zipCodeErr}
      />
      {!readMode && isZipCodeFocused && list.length > 1 && (
        <List ref={ref} items={list} hasZipCode={zipCode.length === 4} onSelect={onSelect} loading={loading} />
      )}
      <TextInput
        label="Ort"
        value={city}
        handleChangeText={updateCity}
        data-id={CITY}
        onFocus={onFocus}
        onBlur={onBlur}
        readMode={readMode}
      />
      {!readMode && focused === CITY && list.length > 0 && (
        <List ref={ref} items={list} hasZipCode={zipCode.length === 4} onSelect={onSelect} loading={loading} />
      )}
      <TextInput label="Kanton" value={canton} handleChangeText={setCanton} readMode={readMode} />
      <Select label="Land" value={country || ''} options={countries} idKey="value" onSelect={setCountry} readMode={readMode} />
    </>
  );
};

AddressAutocomplete.defaultProps = {
  address: '',
  zipCode: '',
  city: '',
  canton: '',
  country: '',
  zipCodeErr: null,
  readMode: false,
};

AddressAutocomplete.propTypes = {
  address: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  canton: PropTypes.string,
  country: PropTypes.string,
  setData: PropTypes.func.isRequired,
  zipCodeErr: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  readMode: PropTypes.bool,
};

export default AddressAutocomplete;
